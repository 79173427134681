import React, { ReactElement } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { ThumbsUp as ConfirmationIcon } from 'lucide-react';
import { useWorkflowId } from '../WorkflowIdContext';

export function Confirmation(
  props: WorkflowNodeProps<{
    prompt?: string;
  }>
): ReactElement {
  const { id } = props;
  const { updateNodeData: update } = useReactFlow();
  const autocomplete = useAutocomplete(props);
  const value = props.data.prompt ?? '';
  const { workflowId } = useWorkflowId();

  return (
    <SingleSourceNode
      icon={
        <ConfirmationIcon className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
      }
      workflowNode={props}
    >
      <LiquidTemplateInput
        className="min-h-40 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={value}
        onChange={(prompt) => update(id, { prompt })}
        workflowId={workflowId}
        nodeType={props.type}
      />
    </SingleSourceNode>
  );
}
