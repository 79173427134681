import { useMutation, useQuery } from '@apollo/client';
import { logger } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '../../../components/Alert';
import { Button } from '../../../components/buttons';
import { createCheckoutSession_Personal_SwitchToPayByCard } from '../../../graphql/billing';
import {
  CreateTeamDocument,
  GetTeamPlanDetailsDocument,
  PricingCurrency,
  TeamIncreaseSeatCountDocument,
  TeamPlanDetails,
  TeamPricing,
  UpgradeToTeamDocument,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { upgradePlan } from '../../../helpers/api/billing';
import {
  selectTeam,
  selectUid,
  selectUserPlan,
  selectUserPricing,
} from '../../../redux/selectors';
import { BillingCycleSwitch } from '../../Credits/BillingCycleSwitch';
import { ChangeTeamSeatsDialog } from '../../Credits/ChangeTeamSeatsDialog';
import { useIsRenewalDisabled } from '../../Credits/ResumeSubscriptionToast';
import { getDefaultPricing } from './helpers';
import { gotTeam } from '../../../redux/modules/user';
import { Coins } from 'lucide-react';
import { ModalDialog } from '../../../components/modals';

export interface BuyMultipleSeatsModalProps {
  open: boolean;
  onClose: () => void;
}

interface PackageProps {
  disabled: boolean;
  setDisabled: (disabled: boolean) => void;
  amount: number;
  pricing: TeamPricing | PricingCurrency;
  isAnnual: boolean;
  isPromo?: boolean;
}

const Package: React.FC<PackageProps> = ({
  disabled,
  setDisabled,
  amount,
  pricing,
  isAnnual,
  isPromo,
}) => {
  const [isLoading, setLoading] = useState(false);
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);
  const intl = useIntl();
  const [createTeam] = useMutation(CreateTeamDocument);
  const [teamIncreaseSeatCount] = useMutation(TeamIncreaseSeatCountDocument);
  const [upgradeToTeam] = useMutation(UpgradeToTeamDocument);
  const dispatch = useDispatch();
  const unitPrice = isAnnual
    ? (pricing.annualAmount / 12).toFixed(0)
    : pricing.monthlyAmount;
  const totalPrice = isAnnual
    ? pricing.annualAmount * amount
    : pricing.monthlyAmount * amount;
  const term = isAnnual ? 'year' : 'month';
  const isCurrentSubscriptionAnnual =
    team?.plan?.status === 'active' && team.plan.recurrenceInterval === 'year';
  const isPlanActive = team?.plan?.status === 'active';
  const isCurrentPlan =
    isPlanActive &&
    team.plan?.quantity === amount &&
    isAnnual === isCurrentSubscriptionAnnual;

  const onUpgrade = useCallback(async () => {
    if (!userId) {
      return;
    }

    if (disabled) return;

    setDisabled(true);
    setLoading(true);

    if (!team) {
      await createTeam();
    }

    trackWebEvent('Clicked Buy Now for multiple seat package', {
      amount,
      unitPrice,
    });

    if (team?.plan?.status === 'active') {
      const team = (
        await teamIncreaseSeatCount({
          variables: {
            quantity: amount,
          },
        })
      ).data?.team_billing_increaseSeatCount;

      team && dispatch(gotTeam(team));
    } else {
      await upgradePlan({
        uid: userId,
        isAnnual,
        seatCount: amount,
        isTeam: true,
        teamId: team?.id,
        createTeam,
        upgradeToTeam,
      });
    }

    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage: 'Your plan has been upgraded.',
        id: 'n7gvWX',
      }),
      { variant: 'SUCCESS' }
    );

    setDisabled(false);
    setLoading(false);
  }, [
    userId,
    disabled,
    setDisabled,
    team,
    amount,
    unitPrice,
    intl,
    createTeam,
    teamIncreaseSeatCount,
    dispatch,
    isAnnual,
    upgradeToTeam,
  ]);

  let button = null;

  if (isCurrentPlan) {
    button = (
      <Button>
        <FormattedMessage
          defaultMessage="This is your current plan."
          id="gBgFvl"
          description="Team view. Buy multiple seats dialog. Current plan text"
        />
      </Button>
    );
  } else if (isPromo) {
    button = (
      <Button href="https://tactiq.typeform.com/to/qK3e5WMK">
        <FormattedMessage
          defaultMessage="Contact us"
          id="OuvwAw"
          description="Team view. Buy multiple seats dialog. Contact us button"
        />
      </Button>
    );
  } else {
    button = (
      <Button color="primary" loading={isLoading} onClick={onUpgrade}>
        {isPlanActive ? (
          <FormattedMessage
            defaultMessage="Change plan"
            id="X0b6H4"
            description="Team view. Buy multiple seats dialog. Change plan button caption"
          />
        ) : (
          <FormattedMessage
            defaultMessage="Buy now"
            id="T4zhga"
            description="Team view. Buy multiple seats dialog. Buy now button caption"
          />
        )}
      </Button>
    );
  }

  return (
    <div className="flex w-full flex-col items-center gap-3 p-2">
      <div className="flex grow flex-col content-between items-center gap-4 text-wrap md:text-nowrap">
        <p className="text-2xl md:text-3xl">
          {amount}
          {isPromo ? '+' : ''}{' '}
          <FormattedMessage
            defaultMessage="seats"
            id="+Ip59h"
            description="Team view. Buy multiple seats dialog. Seats amount"
          />
        </p>
      </div>
      <div className="flex flex-col items-center gap-1">
        <div className="inline text-xl md:text-2xl">
          {pricing.currency}
          {unitPrice}
        </div>
        <div className="text-xs">
          <FormattedMessage
            defaultMessage="per user per month"
            id="AiP7m3"
            description="Team view. Buy multiple seats dialog. Price per month"
          />
        </div>
      </div>
      <div className="inline text-wrap md:text-nowrap">
        <p className="inline text-xs">
          <FormattedMessage
            defaultMessage="Total: "
            id="UnnrtN"
            description="Team view. Buy multiple seats dialog. Total price text."
          />
        </p>
        {pricing.currency}
        {totalPrice}/{term}
      </div>
      {button}
    </div>
  );
};

/**
 * Buy multiple seats modal
 * @param {unknown} param0 params
 * @param {boolean} param0.open is open
 * @param {() => void} param0.onClose close handler
 * @returns {React.FC} a component
 */
export const BuyMultipleSeatsModal: React.FC<BuyMultipleSeatsModalProps> = ({
  open,
  onClose,
}) => {
  const [disabled, setDisabled] = useState(false);
  const [isAnnual, setIsAnnual] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const userPlan = useSelector(selectUserPlan);
  const userPricing = useSelector(selectUserPricing);
  const pricing = getDefaultPricing(userPricing);
  const isUsingPaypal = userPlan.paid?.__typename === 'PaypalPaidPlan';

  useEffect(() => {
    if (isUsingPaypal) {
      setDisabled(true);
    }
  }, [isUsingPaypal]);

  if (!open) return null;

  return (
    <ModalDialog
      open={open}
      size="large"
      onClose={onClose}
      title={
        <FormattedMessage
          defaultMessage="Buy multiple seats"
          id="xe3ytn"
          description="Team view. Buy multiple seats dialog title"
        />
      }
      text={
        <div>
          <BillingCycleSwitch isAnnual={isAnnual} onChange={setIsAnnual} />
          {isUsingPaypal && (
            <>
              <Button
                aria-label="Accept and move to pay by card page."
                loading={isProcessing}
                onClick={async () => {
                  if (!isProcessing) {
                    setProcessing(true);
                    try {
                      window.location.href =
                        await createCheckoutSession_Personal_SwitchToPayByCard(
                          {}
                        );
                    } finally {
                      setProcessing(false);
                    }
                  }
                }}
              >
                <FormattedMessage
                  defaultMessage="Continue to team plan!"
                  id="c++STP"
                />
              </Button>
              <div>
                <Alert
                  severity="info"
                  action={
                    <Button
                      aria-label="Accept and move to pay by card page."
                      loading={isProcessing}
                      onClick={async () => {
                        if (!isProcessing) {
                          setProcessing(true);
                          try {
                            window.location.href =
                              await createCheckoutSession_Personal_SwitchToPayByCard(
                                {}
                              );
                          } finally {
                            setProcessing(false);
                          }
                        }
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Change to card billing"
                        id="PDW8Tn"
                      />
                    </Button>
                  }
                  title={
                    <FormattedMessage
                      defaultMessage="You're currently on PayPal"
                      id="0wiJHo"
                    />
                  }
                  description={
                    <FormattedMessage
                      defaultMessage="Tactiq team requires a pay by card subscription. Before you can move to the Team plan, you  will be taken to the pay by card screen and then we will cancel your PayPal subscription."
                      id="INe7G4"
                    />
                  }
                />
              </div>
            </>
          )}
          <div className="grid grid-flow-row grid-cols-2 grid-rows-2 justify-between p-2 md:flex md:flex-row">
            <div className="border-b border-r border-b-slate-400 border-r-slate-400 p-2 md:border-b-0">
              <Package
                amount={5}
                pricing={pricing}
                disabled={disabled}
                setDisabled={setDisabled}
                isAnnual={isAnnual}
              />
            </div>
            <div className="border-b border-b-slate-400 p-2 md:border-b-0 md:border-r md:border-r-slate-400">
              <Package
                amount={10}
                pricing={pricing}
                disabled={disabled}
                setDisabled={setDisabled}
                isAnnual={isAnnual}
              />
            </div>
            <div className="border-r border-r-slate-400  p-2">
              <Package
                amount={25}
                pricing={pricing}
                disabled={disabled}
                setDisabled={setDisabled}
                isAnnual={isAnnual}
              />
            </div>
            <div className="p-2">
              <Package
                amount={50}
                pricing={pricing}
                disabled={disabled}
                setDisabled={setDisabled}
                isAnnual={isAnnual}
                isPromo
              />
            </div>
          </div>
        </div>
      }
      actions={
        <div className="flex flex-row gap-2 p-3">
          <Button onClick={onClose}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="CIClKk"
              description="Team view. Buy multiple seats dialog. Cancel button title"
            />
          </Button>
        </div>
      }
    />
  );
};

/**
 * useBuyMultipleSeats hook
 * @returns {{button: React.ReactNode, modal: React.ReactNode, onClick: () => void}} return of hook
 */
export const useBuyMultipleSeatsButton = (): {
  button: React.ReactNode;
  modal: React.ReactNode;
  onClick: () => void;
} => {
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);
  const [isBuyMultipleSeatsVisible, setIsBuyMultipleSeatsVisible] =
    useState<boolean>(false);
  const [addMoreSeatsVisible, setAddMoreSeatsVisible] =
    useState<boolean>(false);

  const [teamPlanDetails, setTeamPlanDetails] =
    useState<TeamPlanDetails | null>();
  const getTeamPlanDetails = useQuery(GetTeamPlanDetailsDocument, {
    skip: true,
  });

  const isRenewalDisabled = useIsRenewalDisabled();

  useEffect(() => {
    getTeamPlanDetails
      .refetch()
      .then((r) => setTeamPlanDetails(r.data.teamPlanDetails))
      .catch(logger.error);
  }, [getTeamPlanDetails]);
  const onBuySeatsClick = useCallback(() => {
    if (isRenewalDisabled()) return;
    trackWebEvent('Team View - Buy multiple seats button clicked');
    if (team?.plan?.status === 'active') {
      setAddMoreSeatsVisible(true);
    } else {
      setIsBuyMultipleSeatsVisible(true);
    }
  }, [isRenewalDisabled, team?.plan?.status]);

  if (team && !team.members.some((m) => m.uid === userId && m.roles.ADMIN)) {
    return {
      button: null,
      modal: null,
      onClick: onBuySeatsClick,
    };
  }

  const button = (
    <span>
      <Button
        startIcon={<Coins className="h-4 w-4 text-white" />}
        onClick={onBuySeatsClick}
      >
        <FormattedMessage
          defaultMessage="Buy multiple seats"
          id="kL7G9S"
          description="Team view. Buy multiple seats button title."
        />
      </Button>
    </span>
  );

  const modal = (
    <>
      <BuyMultipleSeatsModal
        open={isBuyMultipleSeatsVisible}
        onClose={() => setIsBuyMultipleSeatsVisible(false)}
      />
      {teamPlanDetails && (
        <ChangeTeamSeatsDialog
          teamPlanDetails={teamPlanDetails}
          upgradeOnly
          completeAction={() => setAddMoreSeatsVisible(false)}
          teamSizeModalOpen={addMoreSeatsVisible}
          onClose={() => setAddMoreSeatsVisible(false)}
        />
      )}
    </>
  );

  return { button, modal, onClick: onBuySeatsClick };
};
