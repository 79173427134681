import React, { ReactElement, ReactNode } from 'react';
import { DayPicker } from 'react-day-picker';
import { Menu } from '../Menu';
import { subDays, startOfDay, endOfDay } from 'date-fns';
import {
  CalendarDays,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  X,
} from 'lucide-react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../buttons';

/**
 * A searchable multi select
 */
export function DatePicker(props: {
  label: ReactNode;
  onChange: (next: { from: Date; to: Date } | undefined) => void;
  value: { from: Date; to: Date } | undefined;
}): ReactElement {
  const { onChange, value, label } = props;
  const todayStart = startOfDay(new Date());
  const todayEnd = endOfDay(new Date());

  const onClickToday = () => onChange({ from: todayStart, to: todayEnd });
  const onClickLastWeek = () =>
    onChange({ from: subDays(todayStart, 6), to: todayEnd });
  const onClcikLastMonth = () =>
    onChange({ from: subDays(todayStart, 29), to: todayEnd });
  const onClear = () => onChange(undefined);
  const hasValue = Boolean(value);

  const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <Menu>
      <Menu.Trigger>
        <Button variant="secondaryOutline" size="small" hasValue={hasValue}>
          <span>
            {label}
            {value && <span>:</span>}
          </span>
          {value
            ? value.from && value.to
              ? dateTimeFormat.formatRange(value?.from, value?.to)
              : dateTimeFormat.format(value.from)
            : null}
          <span className="ml-auto flex-shrink-0">
            {hasValue ? (
              <X size="1rem" onClick={onClear} />
            ) : (
              <ChevronDown size="1rem" />
            )}
          </span>
        </Button>
      </Menu.Trigger>
      <div className="flex items-start gap-2">
        <div className="flex flex-grow flex-col">
          <Menu.Item onClick={onClickToday} icon={<CalendarDays size="1rem" />}>
            <FormattedMessage defaultMessage="Today" id="zWgbGg" />
          </Menu.Item>
          <Menu.Item
            onClick={onClickLastWeek}
            icon={<CalendarDays size="1rem" />}
          >
            <FormattedMessage defaultMessage="Last 7 days" id="irFBKn" />
          </Menu.Item>
          <Menu.Item
            onClick={onClcikLastMonth}
            icon={<CalendarDays size="1rem" />}
          >
            <FormattedMessage defaultMessage="Last 30 days" id="Rfvi9/" />
          </Menu.Item>
        </div>
        <Menu.Item onClick={onClear} className="flex-grow-0">
          <FormattedMessage defaultMessage="Clear" id="/GCoTA" />
        </Menu.Item>
      </div>
      <Menu.Divider />
      <DayPicker
        showOutsideDays
        selected={value}
        onSelect={({ from, to } = { from: undefined, to: undefined }) => {
          if (from && to) return onChange({ from, to });
          if (from && !to) return onChange({ from, to: endOfDay(from) });
          return onChange(undefined);
        }}
        mode="range"
        components={{
          IconLeft: ChevronLeft,
          IconRight: ChevronRight,
        }}
        disabled={{ after: todayEnd }}
        classNames={{
          root: 'relative',
          caption: 'text-center',
          caption_label: 'pt-3 pb-5 leading-none font-semibold',
          table: 'w-full text-center border-collapse',
          head_cell: 'text-xs font-normal text-gray-400 p-0 py-1',
          nav_button_previous: 'absolute left-0 top-0 py-2',
          nav_button_next: 'absolute right-0 top-0 py-2',
          cell: 'text-sm p-0 align-middle',
          day: 'font-medium size-8 my-1',
          day_outside: 'text-gray-400',
          day_disabled: '!text-gray-200',
          day_selected: 'bg-brand-50',
          day_today: 'text-brand',
          day_range_start: '!bg-brand text-white rounded-l',
          day_range_end: '!bg-brand text-white rounded-r',
        }}
      />
    </Menu>
  );
}
