import React, { ReactElement, useState } from 'react';
import { Button } from '../../components/buttons';
import { FormattedMessage } from 'react-intl';
import { SearchFilterInput, SortBy, Workflow } from '../../graphql/operations';
import { Tooltip } from '../../components/Tooltip';
import { SearchPopup } from '../Transcripts/common/SearchBar';
import { useWorkflowRun } from '../../services/Workflow';
import { PlayIcon } from 'lucide-react';

/**
 * Wraps up all the render logic required to be able to run a workflow.
 * - are you allowed to run this workflow
 * - should it save before trying to run
 */
export function RunWorkflowButton(props: {
  workflow: Workflow;
  /** Called once the workflowRun request returns */
  onSuccess: (next: { meetingId: string; executionId: string }) => void;
  /** If a meetingId is not provided, the search box will pop up instead */
  meetingId?: string;
  onSave?: () => Promise<void>;
  loading?: boolean;
}): ReactElement {
  const { workflow, onSave, onSuccess, loading, meetingId } = props;
  const { canRun, id } = workflow;
  const workflowRun = useWorkflowRun();
  const [showMeetingSearch, setShowMeetingSearch] = useState(false);

  async function onRun(meetingId: string) {
    setShowMeetingSearch(false);
    if (onSave) await onSave();
    const execution = await workflowRun.request({
      input: { id, meetingId },
    });
    const executionId = execution.data?.runWorkflow.id;

    if (executionId) onSuccess({ meetingId, executionId });
  }

  const runButton = (
    <Button
      loading={workflowRun.loading}
      disabled={!canRun}
      onClick={() =>
        meetingId ? onRun(meetingId) : setShowMeetingSearch(true)
      }
      startIcon={<PlayIcon size="1rem" />}
    >
      <FormattedMessage defaultMessage="Run" id="KiXNvz" />
    </Button>
  );

  const saveButton = onSave && (
    <Button variant="outlined" loading={loading} onClick={() => onSave()}>
      <FormattedMessage defaultMessage="Save" id="jvo0vs" />
    </Button>
  );

  return (
    <>
      {showMeetingSearch && (
        <MeetingSearch
          open={showMeetingSearch}
          setOpen={setShowMeetingSearch}
          onSelectMeeting={onRun}
        />
      )}
      <div className="flex gap-x-2">
        {saveButton}

        {canRun ? (
          runButton
        ) : (
          <Tooltip
            title={
              <FormattedMessage
                defaultMessage="This workflow contains steps that you don't have permission to run. Try duplicating it and updating those steps."
                id="0QfgnF"
              />
            }
            arrow
            placement="bottom-end"
          >
            {runButton}
          </Tooltip>
        )}
      </div>
    </>
  );
}

function MeetingSearch(props: {
  open: boolean;
  setOpen: (next: boolean) => void;
  onSelectMeeting: (meetingId: string) => void;
}) {
  const [query, setQuery] = useState<SearchFilterInput | undefined>({
    query: '',
  });
  const [sortBy, setSortBy] = useState<SortBy | undefined>(
    SortBy.CREATED_NEWEST_FIRST
  );

  return (
    <div className="min-w-md absolute left-0 right-0 top-[10vh] m-auto w-full max-w-lg md:left-64">
      <SearchPopup
        isOpen={props.open}
        onClose={() => props.setOpen(false)}
        query={query}
        skipEmpty={false}
        setQuery={setQuery}
        sortBy={sortBy}
        setSortBy={setSortBy}
        onSelectMeeting={(meeting) => props.onSelectMeeting(meeting.id)}
      />
    </div>
  );
}
