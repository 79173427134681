import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useIntl } from 'react-intl';
import { enqueueSnackbar } from 'notistack';
import {
  CreateWorkflowDocument,
  GetWorkflowDocument,
  ListWorkflowsDocument,
  UpdateWorkflowDocument,
  WorkflowExecutionUpdatesDocument,
  WorkflowExecutionsMeetingUpdatesDocument,
  RunWorkflowDocument,
  ListWorkflowExecutionsDocument,
  SubmitWorkflowUserInputDocument,
  ArchiveWorkflowDocument,
  WorkflowsInput,
  DuplicateWorkflowDocument,
} from '../graphql/operations';
import type { QueryResult, MutationResult } from './helpers';
import { toHrMinSec } from '../helpers/transcript';
import {
  trackArchiveWorkflow,
  trackCreateWorkflow,
  trackDuplicateWorkflow,
  trackRunWorkflow,
  trackSaveWorkflow,
  trackSubmitWorkflowConfirmation,
} from '../helpers/analytics';

/** Fetch a single workflow */
export function useWorkflowItem(variables: {
  id: string;
}): QueryResult<typeof GetWorkflowDocument> {
  const { data, error, loading } = useQuery(GetWorkflowDocument, {
    variables,
    fetchPolicy: 'cache-and-network',
  });
  return { data, error, loading };
}

/** Return all workflows for a user */
export function useWorkflowList(options: {
  refetch?: boolean;
  input: WorkflowsInput;
}): QueryResult<typeof ListWorkflowsDocument> {
  const { refetch = true, input } = options;
  const { data, error, loading } = useQuery(ListWorkflowsDocument, {
    variables: { input },
    fetchPolicy: refetch ? 'cache-and-network' : 'cache-first',
  });

  return { data, error, loading };
}

/** Create a blank workflow, ready for editing */
export function useWorkflowCreate(): MutationResult<
  typeof CreateWorkflowDocument,
  () => void
> {
  const [fn, { data, loading, error }] = useMutation(CreateWorkflowDocument, {
    variables: {
      input: { name: 'New workflow', definition: { nodes: [], edges: [] } },
    },
  });

  return {
    request: () => {
      trackCreateWorkflow(undefined);
      return fn();
    },
    data,
    error,
    loading,
  };
}

/** Duplicate an existing workflow */
export function useWorkflowDuplicate(
  id: string
): MutationResult<typeof DuplicateWorkflowDocument, () => void> {
  const [fn, { data, loading, error }] = useMutation(
    DuplicateWorkflowDocument,
    {
      variables: {
        input: { id },
      },
      onCompleted: () => {
        enqueueSnackbar('Workflow duplicated successfully!', {
          variant: 'SUCCESS',
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to duplicate workflow', {
          variant: 'ERROR',
        });
      },
    }
  );

  return {
    request: () => {
      trackDuplicateWorkflow(undefined);
      return fn();
    },
    data,
    error,
    loading,
  };
}

/** Archive a single workflow */
export function useWorkflowArchive(): MutationResult<
  typeof ArchiveWorkflowDocument
> {
  const [fn, { data, loading, error }] = useMutation(ArchiveWorkflowDocument);

  return {
    request: (variables) => {
      trackArchiveWorkflow({ workflowId: variables.input.id });
      return fn({ variables: { input: variables.input } });
    },
    data,
    error,
    loading,
  };
}

/** Save changes to a workflow */
export function useWorkflowUpdate(
  onSuccess?: () => void
): MutationResult<typeof UpdateWorkflowDocument> {
  const intl = useIntl();
  const [fn, { data, loading, error }] = useMutation(UpdateWorkflowDocument, {
    onCompleted: () => {
      if (onSuccess) {
        onSuccess();
      }

      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Workflow saved successfully!',
          id: 'bW0kvR',
        }),
        {
          variant: 'SUCCESS',
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        }
      );
    },
    onError: () => {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Failed to save workflow',
          id: 'acBND+',
        }),
        { variant: 'ERROR' }
      );
    },
  });

  return {
    request: (variables) => {
      trackSaveWorkflow({ workflowId: variables.input.id });
      return fn({ variables: { input: variables.input } });
    },
    data,
    error,
    loading,
  };
}

/** Run a workflow */
export function useWorkflowRun(): MutationResult<typeof RunWorkflowDocument> {
  const [fn, { data, loading, error }] = useMutation(RunWorkflowDocument);

  return {
    request: (variables) => {
      const { id, meetingId } = variables.input;
      trackRunWorkflow({ workflowId: id, meetingId });
      return fn({ variables: { input: variables.input } });
    },
    data,
    error,
    loading,
  };
}

/** Approve/Decline workflow confirmation step  */
export function useSubmitWorkflowConfirmation(): MutationResult<
  typeof SubmitWorkflowUserInputDocument
> {
  const [fn, { data, loading, error }] = useMutation(
    SubmitWorkflowUserInputDocument
  );

  return {
    request: (variables) => {
      const { isYes } = variables.input;
      trackSubmitWorkflowConfirmation({ isYes });
      return fn({ variables: { input: variables.input } });
    },
    data,
    error,
    loading,
  };
}

/**
 * Subscribe to the state of a single workflowExecution
 */
export function useWorkflowExecution({
  id,
}: {
  id?: string;
}): QueryResult<typeof WorkflowExecutionUpdatesDocument> {
  const { data, loading, error } = useSubscription(
    WorkflowExecutionUpdatesDocument,
    { variables: { id: id ?? '' }, skip: !id }
  );

  return {
    data,
    error,
    loading,
  };
}

/**
 * Subscribe to the list of executions that require user input
 */
export function useWorkflowExecutionsMeeting({
  meetingId,
}: {
  meetingId?: string;
}): QueryResult<typeof WorkflowExecutionsMeetingUpdatesDocument> {
  const { data, loading, error } = useSubscription(
    WorkflowExecutionsMeetingUpdatesDocument,
    { variables: { meetingId: meetingId ?? '' }, skip: !meetingId }
  );

  return {
    data,
    error,
    loading,
  };
}

/**
 * List out all the executions we have for a single workflow
 */
export function useWorkflowExecutionList({
  workflowId,
  meetingId,
}: {
  workflowId?: string;
  meetingId?: string;
}): QueryResult<typeof ListWorkflowExecutionsDocument> {
  const { data, loading, error } = useQuery(ListWorkflowExecutionsDocument, {
    variables: { input: { workflowId, meetingId } },
  });

  return {
    data,
    error,
    loading,
  };
}

/**
 * Convert a set of optional start and end times into a nice string
 * Return with nothing if the times are not able to represent a duration
 */
export function workflowDuration(start?: number, end?: number): string {
  const duration = start && end ? end - start : null;

  if (duration) return toHrMinSec(duration, false, true);
  return '';
}
