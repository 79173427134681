import React, { Suspense } from 'react';
import {
  createHashRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  Routes,
  useRouteError,
} from 'react-router-dom';
import CreditPageComponent from './app/Credits/CreditPageComponent';
import InvitePageContainer from './app/Credits/InvitePageComponent';
import PaymentSuccessComponent from './app/Credits/PaymentSuccessComponent';
import IntegrationDetails from './app/Integration/IntegrationDetails';
import IntegrationsList from './app/Integration/IntegrationsList';
import LoadingComponent from './app/Landing/Loading/LoadingComponent';
import { MeetingKits } from './app/MeetingKits';
import { MeetingKitPage } from './app/MeetingKits/MeetingKit';
import { MeetingKitItemPage } from './app/MeetingKits/MeetingKitItem';
import { ProductHunt } from './app/ProductHunt';
import { Reporting } from './app/Reporting/Reporting';
import { ReportOwnMeetings } from './app/Reporting/reports/ReportOwnMeetings';
import { Setup } from './app/Setup';
import { TeamPage } from './app/Teams';
import Transcripts from './app/Transcripts/Transcripts';
import TranscriptsArchive from './app/Transcripts/TranscriptsArchive';
import MeetingView from './app/Transcripts/view';
import Settings from './app/UserSettings';
import * as routes from './helpers/routes';
import { ExtensionDeletionContainer } from './app/ExtensionDeletionContainer';
import { ExistingUserFromExtensionSignedIn } from './app/Landing/ExistingUserFromExtensionSignedIn';
import { SignInPage } from './app/Landing/SignInPage';
import { WelcomePage } from './app/Landing/WelcomePage';
import { OauthConsentPage } from './app/OauthConsentPage';
import RequireLoggedIn from './app/RequireLoggedIn';
import RequireLoggedOut from './app/RequireLoggedOut';
import { isProduction } from './helpers/firebase/config';
import SharedWithMe from './app/Transcripts/SharedWithMe';
import SpacePage from './app/Transcripts/SpacePage';
import { useSelector } from 'react-redux';
import { selectTeam } from './redux/selectors';
import WorkflowList from './app/Workflows/WorkflowList';
import featureFlagService from './helpers/feature-flags';
import { WorkflowDiscoveryPage } from './app/Workflows/WorkflowDiscovery';

const WorkflowItem = React.lazy(() => import('./app/Workflows/WorkflowItem'));

let GraphQLPlayground: React.FC | undefined;
if (process.env.IS_PRODUCTION) {
  GraphQLPlayground = undefined;
} else {
  GraphQLPlayground = React.lazy(() => import('./app/graphiql'));
}

const Root = () => {
  const team = useSelector(selectTeam);
  const isWorkflowsEnabled = featureFlagService.isWorkflowsEnabled();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireLoggedIn>
            <Transcripts />
          </RequireLoggedIn>
        }
      />
      <Route
        path="/setup"
        element={
          <RequireLoggedIn>
            <Setup />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kSignUpPage}
        element={
          <RequireLoggedOut>
            <SignInPage mas={null} />
          </RequireLoggedOut>
        }
      />
      <Route
        path={routes.kSignInPage}
        element={
          <RequireLoggedOut>
            <SignInPage mas={null} />
          </RequireLoggedOut>
        }
      />
      <Route
        path={routes.kSignInFromExtensionSuccessPage}
        element={
          <RequireLoggedIn>
            <ExistingUserFromExtensionSignedIn />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kBilling}
        element={
          <RequireLoggedIn>
            <CreditPageComponent />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kCreditPage}
        element={<Navigate to={routes.kBilling} />}
      />
      <Route
        path={routes.kPaymentSuccess}
        element={
          <RequireLoggedIn>
            <PaymentSuccessComponent />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kIntegrations}
        element={
          <RequireLoggedIn>
            <IntegrationsList />
          </RequireLoggedIn>
        }
      />
      <Route
        path={`${routes.kIntegrations}/:id`}
        element={
          <RequireLoggedIn>
            <IntegrationDetails />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kTranscriptsArchive}
        element={
          <RequireLoggedIn>
            <TranscriptsArchive />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kTranscripts}
        element={
          <RequireLoggedIn>
            <Transcripts />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kTranscriptsUpload}
        element={
          <RequireLoggedIn>
            <Transcripts uploadFileOnboarding />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kSharedWithMe}
        element={
          <RequireLoggedIn>
            <SharedWithMe />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kTeamTranscripts}
        element={
          <RequireLoggedIn>
            <Navigate to={`/space/${team?.id}`} replace={true} />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeetingShareIntegration}
        element={
          <RequireLoggedIn>
            <MeetingView showShareModal={true} />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeetingShare}
        element={
          <RequireLoggedIn>
            <MeetingView showShareModal={true} />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeeting}
        element={
          <RequireLoggedIn>
            <MeetingView />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kSpace}
        element={
          <RequireLoggedIn>
            <SpacePage />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kSettings}
        element={
          <RequireLoggedIn>
            <Settings />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kTeamInvite}
        element={
          <RequireLoggedIn>
            <TeamPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kTeam}
        element={
          <RequireLoggedIn>
            <TeamPage />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kReportingOwnMeetings}
        element={
          <RequireLoggedIn>
            <ReportOwnMeetings />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kReporting}
        element={
          <RequireLoggedIn>
            <Reporting />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeetingKitsEditKitItem}
        element={
          <RequireLoggedIn>
            <MeetingKitItemPage isEditing={true} />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeetingKitsViewKitItem}
        element={
          <RequireLoggedIn>
            <MeetingKitItemPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path={routes.kMeetingKitsExplore}
        element={
          <RequireLoggedIn>
            <MeetingKits isExploring={true} />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeetingKitsEditKit}
        element={
          <RequireLoggedIn>
            <MeetingKitPage isEditing={true} />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kMeetingKitsViewKit}
        element={
          <RequireLoggedIn>
            <MeetingKitPage />
          </RequireLoggedIn>
        }
      />

      <Route
        path={routes.kMeetingKits}
        element={
          <RequireLoggedIn>
            <MeetingKits />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kInvite}
        element={
          <RequireLoggedIn>
            <InvitePageContainer />
          </RequireLoggedIn>
        }
      />
      {isWorkflowsEnabled && (
        <>
          <Route
            path={routes.workflowList}
            element={
              <RequireLoggedIn>
                <WorkflowList />
              </RequireLoggedIn>
            }
          />
          <Route
            path={routes.workflowDiscovery}
            element={
              <RequireLoggedIn>
                <WorkflowDiscoveryPage />
              </RequireLoggedIn>
            }
          />
          <Route
            path={routes.workflowItem}
            element={
              <RequireLoggedIn>
                <WorkflowItem tab="builder" />
              </RequireLoggedIn>
            }
          />
          <Route
            path={`${routes.workflowItemActivity}/:executionId?`}
            element={
              <RequireLoggedIn>
                <WorkflowItem tab="activity" />
              </RequireLoggedIn>
            }
          />
        </>
      )}
      <Route
        path={'/product-hunt'}
        element={
          <RequireLoggedIn>
            <ProductHunt />
          </RequireLoggedIn>
        }
      />
      <Route
        path={routes.kOauthConsentScreen}
        element={
          <RequireLoggedIn>
            <OauthConsentPage />
          </RequireLoggedIn>
        }
      />

      {/** Legacy redirect routes */}
      <Route
        path={routes.kLegacyAutoReplace}
        element={<Navigate to={routes.kSettingsAutoCorrect} />}
      />
      <Route
        path={routes.kLegacyAutoHighlight}
        element={<Navigate to={routes.kSettingsTags} />}
      />
      <Route
        path={routes.kLegacyTags}
        element={<Navigate to={routes.kSettingsTags} />}
      />
      <Route
        path={routes.kLegacySettings}
        element={<Navigate to={routes.kSettingsGeneral} />}
      />
      <Route
        path={routes.kLegacyLabels}
        element={<Navigate to={routes.kSettingsLabels} />}
      />
      <Route
        path={routes.kLegacyAccount}
        element={<Navigate to={routes.kSettingsAccount} />}
      />

      <Route path={routes.kSharedMeeting} element={<MeetingView />} />
      <Route
        path={routes.kSharedRestrictedMeeting}
        element={
          <RequireLoggedIn>
            <MeetingView />
          </RequireLoggedIn>
        }
      />

      <Route
        path={routes.kExtensionDeletePage}
        element={<ExtensionDeletionContainer />}
      />

      <Route path={'/loading'} element={<LoadingComponent />} />
      <Route path={routes.kWelcomeUpPage} element={<WelcomePage />} />

      {!isProduction() && GraphQLPlayground && (
        <Route
          path={'/graphql'}
          element={
            <Suspense fallback={<LoadingComponent />}>
              <GraphQLPlayground />
            </Suspense>
          }
        />
      )}
    </Routes>
  );
};

const BubbleError = () => {
  const error = useRouteError();
  throw error;
};

export const TactiqRoutes: React.FC = () => {
  React.useEffect(() => {
    if (window.location.pathname === '/oauth/consent') {
      const url = new URL(window.location.href);
      url.pathname = '/';
      const newUrl = `${url.toString()}#/oauth/consent`;
      window.location.href = newUrl;
    }
  }, []);

  const router = createHashRouter(
    createRoutesFromElements(
      // React router catches the errors and they don't get bubbled up to our error boundary,
      // so we need to catch them and throw them again.
      <Route path="*" element={<Root />} errorElement={<BubbleError />}></Route>
    )
  );

  return (
    <div>
      <Suspense fallback={<LoadingComponent />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
};
