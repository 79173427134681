import { MeetingLinkOrigin, generateMeetingLink } from '@tactiq/model';
import { validate } from 'email-validator';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { HelpCircle, Link, Mail, UserCog2 } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert } from '../../../components/Alert';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';
import { updateMeeting } from '../../../graphql/meetings';
import {
  MeetingReach,
  MeetingShare,
  MeetingShareSettings,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { fetchApiV2 } from '../../../helpers/api/helpers';
import { baseURL } from '../../../helpers/firebase/config';
import { cx } from '../../../helpers/utils';
import icon from '../../../img/link.png';
import {
  IntegrationImplementation,
  IntegrationShare,
  ShareDialogProps,
} from '../../../models/integration';
import {
  getMeetingParticipantEmails,
  selectTeamEmails,
  selectUserEmail,
} from '../../../redux/selectors';
import { Chip } from '../../../components/Chips';
import { Tooltip } from '../../../components/Tooltip';
import { Spinner } from '../../../components/Spinner';
import { RadioGroup, Radio } from '../../../components/Radio';

const CircleClasses = 'rounded-full p-2 flex';

const HelpLink = (props: { url: string; place: string }) => {
  const { url, place } = props;

  return (
    <Tooltip title="Learn more about sharing">
      <Button
        variant="icon"
        onClick={() => {
          trackWebEvent('Sharing - Link - Learn More', { trigger: place });
          window.open(url);
        }}
      >
        <HelpCircle />
      </Button>
    </Tooltip>
  );
};

const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const { meeting, connection, share } = props;

  const teamEmails = useSelector(selectTeamEmails);
  const userEmail = useSelector(selectUserEmail);
  const participantsEmails = getMeetingParticipantEmails(meeting, userEmail);

  const [currentEmail, setCurrentEmail] = useState('');
  const error = currentEmail ? !validate(currentEmail) : false;
  const [emails, setEmails] = useState<string[]>(uniq([...props.emails]));
  const [reach, setReach] = useState(
    props.emails.length
      ? MeetingReach.RESTRICTED
      : meeting.permissions?.allow.reach ?? props.reach ?? MeetingReach.PUBLIC
  );
  const [working, setWorking] = useState(false);
  const intl = useIntl();

  const getSuggestedEmails = (includeTeam: boolean) => {
    return difference(
      uniq([...(includeTeam ? teamEmails : []), ...participantsEmails]),
      emails,
      meeting.permissions?.allow.emails ?? []
    );
  };

  const suggestedEmails = getSuggestedEmails(false);

  const onBlur = useCallback(
    (next: string) => {
      const newEmail = next.trim();

      if (newEmail) {
        const isValid = validate(newEmail);

        if (isValid) {
          setEmails(uniq([...emails, newEmail]));
          setCurrentEmail('');
        } else {
          setCurrentEmail(newEmail);
        }
      }
    },
    [emails, setEmails, setCurrentEmail]
  );

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (
        ['Enter', ' ', 'Tab', ',', ';'].includes(event.key) &&
        currentEmail.trim() &&
        !error
      ) {
        setEmails([...emails, currentEmail.trim()]);
        setCurrentEmail('');
        event.preventDefault();
        return false;
      }
    },
    [emails, setEmails, currentEmail, error]
  );

  const onShare = useCallback(
    async (trigger: string, toSend?: string[], keepOpen?: boolean) => {
      if (working) return;

      try {
        setWorking(true);

        const emailsToSend =
          toSend ??
          uniq([...(meeting.permissions?.allow.emails ?? []), ...emails]);

        if (
          reach === MeetingReach.RESTRICTED &&
          !toSend &&
          currentEmail?.trim()
        ) {
          if (error) {
            enqueueSnackbar(
              intl.formatMessage({
                defaultMessage: 'Please enter a valid email address',
                id: 'qHM8DE',
                description: 'Share by link. Invalid email warning message.',
              }),
              {
                variant: 'WARNING',
                autoHideDuration: 5000,
              }
            );
            return;
          } else {
            emailsToSend.push(currentEmail.trim());
            setEmails(emailsToSend);
            setCurrentEmail('');
          }
        }

        trackWebEvent('Sharing - Link - OnShare', {
          reach,
          emailCount: emailsToSend?.length,
          trigger,
        });

        await share(
          integration,
          connection,
          {
            reach,
            emails: emailsToSend,
            analyticsAttributes: {
              share_type: {
                'done-top': 'Email',
                'done-bottom': 'Link',
                removeaccess: 'RemoveAccess',
              }[trigger],
              recipients_email_count: emails.length,
              recipients_email_total: emailsToSend.length,
            },
          },
          keepOpen
        );
      } finally {
        setWorking(false);
      }
    },
    [
      working,
      meeting.permissions?.allow.emails,
      emails,
      reach,
      currentEmail,
      share,
      connection,
      error,
      intl,
    ]
  );

  // TODO later: show top suggested emails available for clicking a la autocomplete
  // console.log('AC', [currentEmail, ...getSuggestedEmails(true)]);

  return (
    <div className="flex-grow-1 flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div className={cx(CircleClasses, `bg-brand`)}>
          <Mail className="h-6 w-6 text-white" />
        </div>
        <h3 className="flex-1 text-xl">
          <FormattedMessage defaultMessage="Share by email" id="O29TSs" />
        </h3>
        {integration.helpUrl ? (
          <div className="ml-auto">
            <HelpLink place="top" url={integration.helpUrl} />
          </div>
        ) : null}
      </div>

      <div className="flex flex-col gap-2">
        {suggestedEmails.length ? (
          <div className="py-3">
            <Alert
              severity="info"
              action={
                <Button
                  loading={working}
                  onClick={() => {
                    setEmails(uniq([...emails, ...suggestedEmails]));
                  }}
                >
                  <FormattedMessage defaultMessage="Add" id="2/2yg+" />
                </Button>
              }
              description={
                <FormattedMessage
                  defaultMessage="Add meeting participants ({suggestedParticipants})"
                  id="AxeejI"
                  values={{
                    suggestedParticipants: suggestedEmails.join(', '),
                  }}
                />
              }
            />
          </div>
        ) : null}
        <TextInput
          value={currentEmail}
          label="Add emails"
          autoFocus
          error={error}
          type="email"
          onChange={setCurrentEmail}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        <div className="flex flex-wrap items-center gap-2 text-xs">
          {emails.length ? (
            <FormattedMessage
              defaultMessage="The link will be sent to:"
              id="OyYIPi"
            />
          ) : null}
          {emails.map((x) => (
            <Chip
              key={x}
              onDelete={() => setEmails(emails.filter((e) => e !== x))}
            >
              {x}
            </Chip>
          ))}
        </div>
        <div className="flex items-center">
          <div className="ml-auto">
            {currentEmail?.trim() ? (
              <Button
                onClick={() => {
                  if (currentEmail.trim() && !error) {
                    setEmails([...emails, currentEmail.trim()]);
                    setCurrentEmail('');
                  }
                }}
              >
                <FormattedMessage
                  defaultMessage="Add"
                  id="JUTGtH"
                  description="Button title."
                />
              </Button>
            ) : (
              <Button
                loading={working}
                onClick={() => {
                  if (!emails.length) return;
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  onShare('done-top');
                }}
              >
                <FormattedMessage
                  defaultMessage="Share"
                  id="5NXWFI"
                  description="Button title."
                />
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <div className={cx(CircleClasses, `bg-brand`)}>
          <Link className="h-6 w-6 text-white" />
        </div>
        <h3 className="flex-1 text-xl">
          <FormattedMessage
            defaultMessage="Get link"
            description="Share by link. Get link section header."
            id="Qq5uuf"
          />
        </h3>
      </div>

      <RadioGroup
        style={{ paddingLeft: 10 }}
        onChange={(value) => setReach(value as MeetingReach)}
        value={reach}
      >
        <Radio
          value={MeetingReach.RESTRICTED}
          label={
            <FormattedMessage
              defaultMessage="Restricted"
              id="e2JnYx"
              description="Share by link. Restricted radio button label."
            />
          }
          description={
            <FormattedMessage
              defaultMessage="Only users with access can view this meeting using this link"
              description="Share by link. Restricted reach message."
              id="AjGNVB"
            />
          }
        />
        <Radio
          value={MeetingReach.PUBLIC}
          label={
            <FormattedMessage
              defaultMessage="Everyone with the link"
              description="Share by link. Public radio button label."
              id="sFjjBt"
            />
          }
          description={
            <FormattedMessage
              defaultMessage="Everyone with a Tactiq account with this link can view this meeting"
              description="Share by link. Public reach message."
              id="JIeEFd"
            />
          }
        />
      </RadioGroup>

      <div className="flex items-center">
        <div className="ml-auto">
          <Button
            onClick={() =>
              onShare('done-bottom', meeting.permissions?.allow.emails)
            }
            loading={working}
          >
            <FormattedMessage
              defaultMessage="Save and Copy Link"
              id="vNT6uX"
              description="Button title."
            />
          </Button>
        </div>
      </div>

      {(meeting.permissions?.allow.emails.length ?? 0) > 0 && (
        <>
          <div className="flex items-center gap-4">
            <div className={cx(CircleClasses, `bg-brand`)}>
              <UserCog2 className="h-6 w-6 text-white" />
            </div>
            <h3 className="flex-1 text-xl">
              <FormattedMessage
                defaultMessage="Users with access"
                id="uCjGnA"
              />
            </h3>
            {working && <Spinner />}
          </div>

          <div className="flex flex-wrap items-center gap-2">
            {reach === MeetingReach.PUBLIC ? (
              <Chip
                disabled={working}
                onDelete={() => setReach(MeetingReach.RESTRICTED)}
              >
                <FormattedMessage
                  defaultMessage="Everyone with the link"
                  id="PCRS51"
                />
              </Chip>
            ) : null}

            {meeting.permissions?.allow.emails.map((x) => (
              <Chip
                disabled={working}
                key={x}
                onDelete={() =>
                  onShare(
                    'removeaccess',
                    meeting.permissions?.allow.emails.filter((e) => e !== x),
                    true
                  )
                }
              >
                {x}
              </Chip>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options, keepOpen) => {
  await updateMeeting({
    id: options.meetingId,
    rawTranscript: options.rawTranscript,
    permissions: {
      reach: options.reach ?? MeetingReach.RESTRICTED,
      emails: options.emails ?? [],
      settings: {
        isSharingDetails: options.isSharingDetails,
        isSharingHighlights: options.isSharingHighlights,
        isSharingNotes: options.isSharingNotes,
        isSharingTranscript: options.isSharingTranscript,
      },
    },
  });

  return {
    link: generateMeetingLink(
      baseURL,
      { meetingId: options.meetingId },
      MeetingLinkOrigin.SHARING_LINK
    ),
    keepOpen,
  };
};

const unshareImpl = async (
  meetingId: string,
  shareData?: MeetingShare
): Promise<void> => {
  const endpoint =
    shareData && 'key' in shareData.options
      ? `/a/meeting/${meetingId}/share/${shareData.options.key}`
      : `/a/meeting/${meetingId}/share`;
  await fetchApiV2(endpoint, {
    method: 'DELETE',
  });
};

export const integration: IntegrationImplementation = {
  order: 1,
  connector: () => <div></div>,
  id: 'link',
  title: 'Link',
  icon,
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Shareable link"
      id="VQXH7k"
      description="Share by link. Integration share description."
    />
  ),
  share: shareImpl,
  ShareDialog,
  getSharingDestinationTitle: (
    options: MeetingShareSettings & { emails?: string[]; reach?: MeetingReach }
  ) => {
    const whats: string[] = [];
    if (options.isSharingDetails) whats.push('meeting details');
    if (options.isSharingNotes) whats.push('notes');
    if (options.isSharingHighlights) whats.push('highlights');
    if (options.isSharingTranscript) whats.push('transcript');

    const what =
      options.isSharingDetails &&
      options.isSharingNotes &&
      options.isSharingHighlights &&
      options.isSharingTranscript
        ? 'entire meeting'
        : whats.join(', ');

    if (options.reach === MeetingReach.RESTRICTED) {
      return `Shared ${what} with ${(options.emails ?? []).join(', ')}`;
    } else {
      return `Shared ${what} with everyone with the link`;
    }
  },
  isConnected: () => true,
  unshare: unshareImpl,
  helpUrl: 'https://help.tactiq.io/en/articles/5605311-sharing-with-links',
};

export default integration;
