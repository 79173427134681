import React from 'react';
import { ShareCardWrapper } from './ShareCardWrapper';
import { FormattedMessage, useIntl } from 'react-intl';
import { AddToSpaceMenu } from '../../../Spaces/AddToSpaceMenu';
import { useMeeting } from '../../common/meeting-hooks';
import { Button, TLoading } from '../../../../components/buttons';
import { Tooltip } from '../../../../components/Tooltip';
import { Plus, X, Component } from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectUserSpaces } from '../../../../redux/selectors';
import { RemoveFromSpaceDocument, Space } from '../../../../graphql/operations';
import { Link } from '../../../../components/Link';

import { enqueueSnackbar } from 'notistack';
import { BasicMeeting } from '../../../../models/meeting';
import { useMutation } from '@apollo/client';

const SpaceTab: React.FC<{
  space: Pick<Space, 'id' | 'icon' | 'name'>;
  meeting: BasicMeeting;
}> = ({ space, meeting }) => {
  const [removeFromSpace, removeSpaceMutation] = useMutation(
    RemoveFromSpaceDocument
  );

  return (
    <div className="flex flex-row flex-nowrap items-center gap-1.5  rounded-md border border-solid border-slate-200 bg-white px-1.5 py-1">
      <Link noUnderline to={`/space/${space.id}`}>
        <div className="flex flex-row flex-nowrap items-center gap-1.5 truncate">
          <span className="flex size-3.5 items-center justify-center text-slate-600">
            {space.icon}
          </span>
          <Tooltip arrow placement="top" title={space.name}>
            <span className="overflow-hidden text-ellipsis text-xs font-medium text-slate-600">
              {space.name}
            </span>
          </Tooltip>
        </div>
      </Link>
      {removeSpaceMutation.loading ? (
        <TLoading size="small" />
      ) : (
        <button
          className="size-3"
          onClick={async () => {
            await removeFromSpace({
              variables: {
                input: {
                  meetingId: meeting.id,
                  spaceId: space.id,
                },
              },
            });
            enqueueSnackbar(
              <FormattedMessage
                defaultMessage="Meeting has been removed from the space - {spaceIcon} {spaceName}"
                id="hnzejC"
                values={{
                  spaceIcon: space.icon,
                  spaceName: space.name,
                }}
              />,
              { variant: 'SUCCESS' }
            );
          }}
        >
          <X className="size-3 text-slate-600" />
        </button>
      )}
    </div>
  );
};

export const SharedInSpaceCard: React.FC = () => {
  const intl = useIntl();
  const meeting = useMeeting();
  const spaces = useSelector(selectUserSpaces);

  if (!meeting) {
    return null;
  }

  return (
    <ShareCardWrapper
      title={intl.formatMessage({ defaultMessage: 'In Spaces', id: 'QAxaPS' })}
      imageComponent={
        <Component strokeWidth={2} size={16} className="text-slate-600" />
      }
      centerContent={
        <div className="flex w-full flex-row flex-wrap gap-1 p-0.5">
          {spaces
            .filter((space) =>
              meeting.permissions?.allow.spaces?.includes(space.id)
            )
            .map((space) => {
              return (
                <SpaceTab key={space.id} space={space} meeting={meeting} />
              );
            })}
        </div>
      }
      actions={
        <AddToSpaceMenu
          meeting={meeting}
          trigger={
            <Button size="tiny" variant="icon" onClick={() => {}}>
              <Plus strokeWidth={2} size={18} className="text-slate-600" />
            </Button>
          }
        />
      }
    />
  );
};
