import React, { ReactElement, ReactNode, useState } from 'react';

import {
  Header,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  RowData,
  getPaginationRowModel,
  PaginationState,
} from '@tanstack/react-table';

import {
  ArrowDown,
  ArrowUp,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUpDown,
} from 'lucide-react';
import { TextInput } from '../TextInput';
import { FormattedMessage, useIntl } from 'react-intl';
import { Combobox } from '../Combobox';
import { Menu } from '../Menu';
import { Button } from '../buttons';
import { cx } from '../../helpers/utils';

export type ColumnDefinition<T> = ColumnDef<T>;

declare module '@tanstack/react-table' {
  // the extending only works with the exact same defintion
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    ascLabel?: ReactNode;
    descLabel?: ReactNode;
    filterLabel?: ReactNode;
    align?: 'left' | 'right' | 'center';
    stopEventPropagation?: boolean;
  }
}
const pageSizes = [10, 25, 50, 100];

/**
 * Render large arrays in a tabluar format, with sorting, searching and filtering
 */
export function Table<T>(props: {
  columns: Array<
    ColumnDef<T> & {
      ascLabel?: string;
      descLabel?: string;
    }
  >;
  data: Array<T>;
  search?: boolean;
  actions?: () => ReactNode;
  searchPlaceholder?: string;
  initialPageSize?: number;
  onRowClick?: (row: T) => void;
}): ReactElement {
  const intl = useIntl();
  const {
    columns,
    data,
    actions,
    search,
    initialPageSize = pageSizes[0],
    searchPlaceholder = intl.formatMessage({
      defaultMessage: 'Search...',
      id: '0BUTMv',
    }),
  } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: initialPageSize,
  });

  const table = useReactTable<T>({
    columns,
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    defaultColumn: {
      enableColumnFilter: false,
      enableSorting: false,
      filterFn: (row, columnId, filterValue) => {
        if (filterValue.length === 0) return true;
        return filterValue.some((f: unknown) =>
          new Array<unknown>().concat(row.getValue(columnId)).includes(f)
        );
      },
    },
    state: {
      globalFilter,
      sorting,
      pagination,
    },
  });

  const filters = table
    .getHeaderGroups()
    .flatMap((group) => group.headers.filter((ii) => ii.column.getCanFilter()));

  const thClasses = 'p-2 text-left text-sm font-semibold text-slate-700';
  const borderClasses = 'border-t border-gray-200 p-2';

  const hasFooter = table
    .getFooterGroups()
    .map(({ headers }) => headers.map(({ column }) => column.columnDef.footer))
    .flat()
    .some(Boolean);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        {search && (
          <div className="max-w-md">
            <TextInput
              placeholder={searchPlaceholder}
              onChange={(value) => setGlobalFilter(value)}
            />
          </div>
        )}
        {filters.map((header) => (
          <Filter key={header.id} header={header} />
        ))}
        {actions && <div className="ml-auto">{actions?.()}</div>}
      </div>
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const meta = header.column.columnDef.meta;
                const sort = (desc: boolean) => {
                  table.setSorting([
                    {
                      desc,
                      id: header.id,
                    },
                  ]);
                };
                const content = flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                );
                const canSort = header.column.getCanSort();

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cx(thClasses, canSort ? 'relative -left-3' : '')}
                  >
                    {canSort ? (
                      <Menu>
                        <Menu.Trigger>
                          <Button size="small" variant="naked">
                            <div className="flex items-center gap-2 ">
                              {content}
                              {{
                                asc: <ArrowUp size="1rem" />,
                                desc: <ArrowDown size="1rem" />,
                                false: <ChevronsUpDown size="1rem" />,
                              }[String(header.column.getIsSorted())] ?? null}
                            </div>
                          </Button>
                        </Menu.Trigger>
                        <Menu.Item
                          icon={<ArrowUp size="1rem" />}
                          onClick={() => sort(false)}
                        >
                          {meta?.ascLabel ?? (
                            <FormattedMessage
                              defaultMessage="Ascending"
                              id="u7djqV"
                            />
                          )}
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => sort(true)}
                          icon={<ArrowDown size="1rem" />}
                        >
                          {meta?.descLabel ?? (
                            <FormattedMessage
                              defaultMessage="Descending"
                              id="aleGqT"
                            />
                          )}
                        </Menu.Item>
                      </Menu>
                    ) : (
                      content
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className={cx(
                  props.onRowClick && 'cursor-pointer',
                  'group/row'
                )}
                onClick={() => props.onRowClick?.(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      className={cx(borderClasses, 'p-2')}
                      align={cell.column.columnDef.meta?.align}
                      style={{
                        width: cell.column.getSize(),
                      }}
                      onClick={(event) => {
                        if (cell.column.columnDef.meta?.stopEventPropagation)
                          event.stopPropagation();
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {hasFooter && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th className={cx(thClasses, borderClasses)} key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
      {table.getRowCount() > initialPageSize && (
        <div className="flex items-center gap-8 p-3 text-sm">
          <div className="mr-auto">
            <FormattedMessage
              defaultMessage="Showing {subset} of {total} rows"
              id="ApqIhj"
              values={{
                subset: table.getRowModel().rows.length.toLocaleString(),
                total: table.getRowCount().toLocaleString(),
              }}
            />
          </div>

          <span className="flex items-center gap-1">
            <FormattedMessage
              defaultMessage="Page {start} of {end}"
              id="QmkUfS"
              values={{
                start: table.getState().pagination.pageIndex + 1,
                end: table.getPageCount(),
              }}
            />
          </span>

          <Combobox
            id={(x) => String(x)}
            name={(x) => String(x)}
            options={pageSizes}
            value={table.getState().pagination.pageSize}
            onChange={(next) => table.setPageSize(next ?? pageSizes[0])}
            multiple={false}
            hideSearch
            keepSelected
            highlightWhenSelected={false}
            label={
              <FormattedMessage defaultMessage="Rows per page" id="VCZBMt" />
            }
          />

          <div className="flex items-center gap-2">
            <Button
              variant="light"
              onClick={() => table.firstPage()}
              disabled={!table.getCanPreviousPage()}
              startIcon={<ChevronsLeft size="1rem" />}
            />
            <Button
              variant="light"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              startIcon={<ChevronLeft size="1rem" />}
            />
            <Button
              variant="light"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              startIcon={<ChevronRight size="1rem" />}
            />
            <Button
              variant="light"
              onClick={() => table.lastPage()}
              startIcon={<ChevronsRight size="1rem" />}
            />
          </div>
        </div>
      )}
    </div>
  );
}

function Filter<Data, Value>({ header }: { header: Header<Data, Value> }) {
  const { getFilterValue, setFilterValue, columnDef } = header.column;
  return (
    <Combobox<string>
      multiple
      value={(getFilterValue() as string[]) ?? []}
      label={
        columnDef.meta?.filterLabel ??
        flexRender(columnDef.header, header.getContext())
      }
      id={(x) => x}
      name={(x) => String(x)}
      onRemove={() => setFilterValue(undefined)}
      onChange={setFilterValue}
      options={[
        // Get a unique set of values, even if the values are arrays
        ...new Set(
          Array.from(header.column.getFacetedUniqueValues().keys()).flat()
        ),
      ].sort((a, b) =>
        String(a).localeCompare(String(b), undefined, {
          sensitivity: 'base',
          numeric: true,
        })
      )}
    />
  );
}
