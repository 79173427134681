import { useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { Copy, Trash2, Save, Loader, X } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useState, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { inputBaseClasses } from '../../../../components/TextInput';
import { RichTextInput } from '../../../../components/RichTextInput';
import { Button } from '../../../../components/buttons';
import {
  DeleteAiPromptOutputDocument,
  MeetingAiPromptOutput,
  MeetingKitItemOutputType,
  UpdateAiPromptOutputDocument,
  MeetingAiOutputTypeString,
  MeetingAiOutputsContent,
} from '../../../../graphql/operations';
import { trackWebEvent } from '../../../../helpers/analytics';
import { copyToClipboard } from '../../../../helpers/clipboard';
import { isMeetingEditable } from '../../../../helpers/meetings';
import { useFullMeeting } from '../../common/meeting-hooks';
import { scrollToTimestamp } from '../../common/scroll-helpers';
import { VoteButtons } from './VoteButtons';
import { Alert } from '../../../../components/Alert';
import { cx } from '../../../../helpers/utils';
import { RootState } from '../../../../redux/store';
import { setAiOutputContent } from '../../../../redux/modules/global';
import { AddToMeetingKitModal } from './AddToMeetingKitModal';
import { ActionItems } from './ActionItems';
import { convertAIOutputContentToString } from '../../../../helpers/transcript';
import Magic from '../../../Common/icons/Magic';
import { MultiLineEllipsis } from '../../../../components/MultiLineEllipsis';
import { Avatar } from '../../../../components/Avatar';

/**
 * Returns false if we should not offer "save" button for this output
 * @param {output} output from the AI prompt
 * @returns {boolean} boolean
 */
function canSaveAsNewKitItem(output: MeetingAiPromptOutput): boolean {
  if (output.isSystemPrompt) {
    return false;
  }

  if (output.meetingKitId && output.meetingKitItemId) {
    return false;
  }

  if (output.content?.__typename === 'MeetingAIOutputTypeActionItems') {
    return false;
  }

  return true;
}

export const AIPromptOutput: React.FC<{
  output: MeetingAiPromptOutput;
  readOnly?: boolean;
  title?: string;
  icon?: React.ReactNode;
}> = ({ readOnly, output, title, icon }) => {
  const meeting = useFullMeeting();
  const intl = useIntl();
  const currentUserId = useSelector((state: RootState) => state.user.id);
  const isEditable = !readOnly && isMeetingEditable(meeting);
  const isWhoAskedPrompt = output.askedBy?.uid === currentUserId;
  const canEditOutputs = isEditable || isWhoAskedPrompt;
  const [isAddToKitDialogOpen, setAddToKitDialogOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [deleteAiPromptOutput, deleteAiPromptOutputMutation] = useMutation(
    DeleteAiPromptOutputDocument
  );

  const displayTitle = output.isSystemPrompt
    ? title ?? output.promptTitle ?? output.prompt
    : output.promptTitle ?? output.prompt;
  const displayIcon = icon ?? <Magic />;

  let actions;
  let content;
  let askedByContent;
  let contentGeneratingMsg;

  const askedBy = output.askedBy
    ? {
        uid: output.askedBy.uid,
        displayName: output.askedBy.displayName,
        photoURL: output.askedBy.photoUrl,
      }
    : {
        uid: meeting?.user?.id,
        displayName: meeting?.user?.displayName,
        photoURL: meeting?.user?.photoURL,
      };

  if (askedBy?.uid !== currentUserId) {
    askedByContent = (
      <div className="flex flex-row items-center justify-end gap-1">
        <p className="text-sm font-semibold">
          <FormattedMessage
            defaultMessage="Asked by: {name}"
            id="tvA9Tx"
            values={{
              name: askedBy.displayName,
            }}
          />
        </p>
        {askedBy?.photoURL && (
          <Avatar
            src={askedBy.photoURL}
            name={askedBy.displayName ?? 'Tactiq User'}
            alt={`Profile photo of ${askedBy.displayName}`}
            className="h-5 w-5"
          />
        )}
      </div>
    );
  }

  if (output.content) {
    const deleteAction = canEditOutputs ? (
      <Button
        size="small"
        loading={deleteAiPromptOutputMutation.loading}
        startIcon={<Trash2 className="h-4 w-4 text-slate-600" />}
        variant="naked"
        onClick={async () => {
          if (!meeting) return;

          trackWebEvent('AI Summary – Prompt deleted', {
            meetingId: meeting.id,
            prompt: output.prompt,
            finishReason: output.finishReason,
          });
          await deleteAiPromptOutput({
            variables: {
              input: {
                meetingId: meeting.id,
                outputPromptId: output.requestedAt.toString(),
              },
            },
          });
        }}
      >
        <FormattedMessage defaultMessage="Delete " id="8TEwqV" />
      </Button>
    ) : null;

    if (output.finishReason === 'content_filter') {
      content = (
        <div className="text-sm">
          <Alert
            variant="light"
            description={
              <FormattedMessage
                defaultMessage="We are unable to generate a response due to detected harmful content."
                id="q1N34q"
              />
            }
            severity="warning"
          />
        </div>
      );
      actions = deleteAction;
    } else {
      actions = (
        <div className="flex flex-row items-center gap-1">
          <VoteButtons output={output} />
          <Button
            startIcon={<Copy className="h-4 w-4 text-slate-600" />}
            size="small"
            variant="naked"
            onClick={() => {
              if (!meeting) return;

              trackWebEvent('AI Summary – Prompt output coppied', {
                meetingId: meeting.id,
                prompt: output.prompt,
              });
              copyToClipboard(convertAIOutputContentToString(output))
                .then(() => {
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'Copied to clipboard',
                      id: '93oVTh',
                    }),
                    { variant: 'SUCCESS' }
                  );
                })
                .catch(() => {
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'Failed to copy to clipboard',
                      id: 'ce5Hp1',
                    }),
                    { variant: 'ERROR' }
                  );
                });
            }}
          >
            <FormattedMessage defaultMessage="Copy" id="4l6vz1" />
          </Button>
          {canSaveAsNewKitItem(output) ? (
            <Button
              startIcon={<Save className="h-4 w-4 text-slate-600" />}
              size="small"
              variant="naked"
              onClick={() => setAddToKitDialogOpen(true)}
            >
              <FormattedMessage defaultMessage="Save" id="jvo0vs" />
            </Button>
          ) : null}
          {deleteAction}
        </div>
      );
      content = (
        <AIPromptOutputView
          meetingId={meeting?.id ?? 'wut'}
          content={output.content}
          contentType={output.contentType}
          isEditable={isEditable}
          promptId={output.requestedAt.toString()}
        />
      );
    }
  } else {
    if (Date.now() - output.requestedAt > 1000 * 60) {
      actions = canEditOutputs ? (
        <Button
          size="small"
          loading={deleteAiPromptOutputMutation.loading}
          endIcon={<X className="h-4 w-4 text-slate-600" />}
          variant="naked"
          onClick={async () => {
            if (!meeting) return;

            trackWebEvent('AI Summary – Unsuccessful prompt output deleted', {
              meetingId: meeting.id,
              prompt: output.prompt,
            });
            await deleteAiPromptOutput({
              variables: {
                input: {
                  meetingId: meeting.id,
                  outputPromptId: output.requestedAt.toString(),
                },
              },
            });
          }}
        >
          <FormattedMessage defaultMessage="Dismiss" id="TDaF6J" />
        </Button>
      ) : (
        <></>
      );

      content = (
        <div className="text-sm">
          <Alert
            variant="light"
            description={
              <FormattedMessage
                defaultMessage="There was a problem generating a response."
                id="CDe1sJ"
              />
            }
            severity="warning"
          />
        </div>
      );
    } else {
      actions = null;
      content = null;
      contentGeneratingMsg = (
        <div className="flex items-center text-sm font-semibold">
          <Loader className="mr-2 h-4 w-4 text-slate-400 motion-safe:animate-spin" />
          <span className="text-indigo-600">
            <FormattedMessage
              defaultMessage="Building your response..."
              id="o6Xaio"
            />
          </span>
        </div>
      );
    }
  }

  const canViewKitItem =
    output.meetingKitId &&
    output.meetingKitItemId &&
    output.prompt &&
    output.prompt !== 'System Prompt';

  return (
    <div
      className={cx(
        'flex flex-col gap-2 rounded-lg border p-6',
        contentGeneratingMsg && 'border-dashed border-indigo-200 bg-slate-50'
      )}
      id={`meetingKitItemOutput-${output.meetingKitItemId}`}
    >
      <div className="flex flex-row flex-wrap justify-between gap-x-2 gap-y-1">
        <div className="flex">
          <span className="mt-1 flex-1">{displayIcon}</span>
          <h3
            className={cx(
              'ml-2 text-lg font-semibold',
              canViewKitItem ? 'cursor-pointer hover:underline' : ''
            )}
            role={canViewKitItem ? 'link' : undefined}
            onClick={() =>
              canViewKitItem &&
              navigate(
                `/meeting-kits/${output.meetingKitId}/${output.meetingKitItemId}/edit`
              )
            }
          >
            <MultiLineEllipsis>
              {displayTitle || output.prompt}
            </MultiLineEllipsis>
          </h3>
        </div>
        <div className="flex justify-between">
          {contentGeneratingMsg}
          <div className="flex flex-row items-center gap-1">{actions}</div>
        </div>
      </div>
      {content}
      {askedByContent}
      <AddToMeetingKitModal
        open={isAddToKitDialogOpen}
        onClose={() => {
          setAddToKitDialogOpen(false);
        }}
        meetingId={meeting?.id ?? ''}
        promptSource={output}
      />
    </div>
  );
};

const MarkdownOutputView: React.FC<{
  content: MeetingAiOutputTypeString;
  isEditable: boolean;
  meetingId: string;
  promptId: string;
  contentType: MeetingKitItemOutputType;
}> = ({ content, isEditable, meetingId, promptId, contentType }) => {
  const dispatch = useDispatch();
  const textContent = content.text
    .replace('```markdown', '')
    .replace('```', '');

  const [updateAIPromptOutput, mutationState] = useMutation(
    UpdateAiPromptOutputDocument
  );

  const onSave = useCallback(
    (markdownContent: string) => {
      if (markdownContent === textContent) return;
      dispatch(
        setAiOutputContent({
          meetingId,
          outputPromptId: promptId,
          content: markdownContent,
        })
      );
      updateAIPromptOutput({
        variables: {
          input: {
            meetingId,
            outputPromptId: promptId,
            content: markdownContent,
            contentType,
          },
        },
      }).catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Failed to save AI output content', e);
      });
    },
    [
      dispatch,
      updateAIPromptOutput,
      meetingId,
      promptId,
      contentType,
      textContent,
    ]
  );

  return (
    <div className="relative">
      {mutationState.loading && (
        <Loader className="absolute bottom-0 right-2 h-4 w-4 text-slate-400 motion-safe:animate-spin" />
      )}
      <RichTextInput
        value={textContent}
        onChange={onSave}
        debounce={1000}
        onBlur={onSave}
        onCitationClick={(from, to) => {
          scrollToTimestamp(1000 * from, 1000 * to);
        }}
        isEditable={isEditable}
      />
    </div>
  );
};

export const AIPromptOutputView: React.FC<{
  meetingId: string;
  content: MeetingAiOutputsContent;
  contentType: MeetingKitItemOutputType;
  promptId: string;
  isEditable: boolean;
}> = ({ meetingId, content, contentType, isEditable, promptId }) => {
  if (content.__typename === 'MeetingAIOutputTypeActionItems') {
    return (
      <div className={(cx('form-textarea', inputBaseClasses), 'ml-4')}>
        <ActionItems
          meetingId={meetingId}
          list={content.actionItems ?? []}
          isEditable={isEditable}
        />
      </div>
    );
  } else {
    return (
      <div className={cx('form-textarea', inputBaseClasses, 'ml-4')}>
        <MarkdownOutputView
          content={content as MeetingAiOutputTypeString}
          isEditable={isEditable}
          meetingId={meetingId}
          promptId={promptId}
          contentType={contentType}
        />
      </div>
    );
  }
};
