import React, { useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { cx } from '../../helpers/utils';
import { Button } from '../buttons';

/**
 * `MultiLineEllipsis` is a React component that displays a string of text (`children`)
 * and truncates it with an ellipsis if it exceeds two lines. If the text is truncated,
 * a "Show more" button is displayed. When clicked, the button expands the text to show
 * the full content.
 *
 * @component
 * @param {string} children - The text to be displayed and potentially truncated.
 *
 * @example
 * <MultiLineEllipsis>
 *   This is a long string that will be truncated if it exceeds two lines.
 * </MultiLineEllipsis>
 */
export const MultiLineEllipsis: React.FC<{
  children: string;
}> = ({ children }) => {
  const headerRef = useRef<HTMLParagraphElement>(null);
  const [isClamped, setIsClamped] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useLayoutEffect(() => {
    if (headerRef?.current) {
      const headerEl = headerRef.current;
      setIsClamped(headerEl.scrollHeight > headerEl.clientHeight);
    }
  }, []);

  return (
    <div>
      <p
        ref={headerRef}
        className={cx(isExpanded ? 'line-clamp-none	' : 'line-clamp-2')}
      >
        {children}
      </p>
      {isClamped && !isExpanded && (
        <Button variant="naked" size="text" onClick={() => setIsExpanded(true)}>
          <FormattedMessage defaultMessage="Show more" id="aWpBzj" />
        </Button>
      )}
    </div>
  );
};
