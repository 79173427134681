import React, { ReactElement } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNode, WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import { TextInput } from '../../../components/TextInput';
import { Checkbox } from '../../../components/Checkbox';
import { FormattedMessage } from 'react-intl';
import { Layers as TemplateIcon } from 'lucide-react';
import {
  Completion,
  LiquidTemplateInput,
} from '../../../components/LiquidTemplateInput';
import { useAutocomplete } from './useAutocomplete';
import { useWorkflowId } from '../WorkflowIdContext';

export type CustomPromptData = {
  template: string;
  title: string;
  saveToMeeting: boolean;
};

/**
 * A node to process previous steps in a liquid template,
 * and optionally save the result to a meeting.
 */
export function Template(
  props: WorkflowNodeProps<CustomPromptData>
): ReactElement {
  const { id, data } = props;
  const { updateNodeData: update } = useReactFlow();
  const autocomplete = useAutocomplete(props);
  const { workflowId } = useWorkflowId();

  const input = (
    <div className="flex grow flex-col gap-3">
      <LiquidTemplateInput
        className="min-h-10 text-base"
        properties={autocomplete.properties}
        variables={autocomplete.variables}
        value={data.template}
        onChange={(template) => update(id, { template })}
        workflowId={workflowId}
        nodeType={props.type}
      />
      <Checkbox
        id={`${id}-applyToMeeting`}
        checked={data.saveToMeeting}
        label={
          <FormattedMessage defaultMessage="Save to the meeting" id="OplEOI" />
        }
        onChange={(saveToMeeting) => update(id, { saveToMeeting })}
      />
      {data.saveToMeeting && (
        <div>
          <TextInput
            placeholder="title"
            label="Title"
            value={data.title}
            onChange={(title) => update(id, { title })}
          />
        </div>
      )}
    </div>
  );

  return (
    <SingleSourceNode
      icon={
        <TemplateIcon className="size-8 rounded-md bg-brand-50 p-2 text-brand" />
      }
      workflowNode={props}
    >
      {input}
    </SingleSourceNode>
  );
}

Template.autocompleteProperties = function (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  node: WorkflowNode,
  path: string
): Completion[] {
  if (path.length === 0) {
    return [
      {
        label: 'output',
      },
    ];
  }
  return [];
};
