import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectIsEnhancedPrivacyEnabled } from '../../../../redux/selectors';
import { RootState } from '../../../../redux/store';
import { useMeeting } from '../../common/meeting-hooks';
import TranscriptSection from '../TranscriptSection';
import { ParticipantStats as ParticipantStatsImpl } from './ParticipantStats';
import { Edit3 } from 'lucide-react';
import { Button } from '../../../../components/buttons';
import { ModalDialog } from '../../../../components/modals';
import { Tooltip } from '../../../../components/Tooltip';
import { MeetingParticipant } from '../../../../graphql/operations';
import { TextInput } from '../../../../components/TextInput';
import { Avatar, SpeakerColourProps } from '../../../../components/Avatar';
import {
  trackMeetingParticipantNameChanged,
  trackEditParticipantNamesModalOpened,
  trackEditParticipantNamesModalClosed,
} from '../../../../helpers/analytics';
import { enqueueSnackbar } from 'notistack';
import { useUpdateMeetingParticipantNames } from '../../../../services/Meeting';

export const EditParticipantNameModal: React.FC<{
  open: boolean;
  onClose: () => void;
  participants: Pick<MeetingParticipant, 'name' | 'color'>[];
  meetingId: string;
}> = ({ open, onClose, participants, meetingId }) => {
  const intl = useIntl();
  const initialNames = participants.map((participant, index) => {
    return {
      index,
      name: participant.name,
      color: participant.color,
    };
  });
  const [participantNames, setParticipantNames] = React.useState(initialNames);
  const [error, setError] = React.useState<number | null>(null);

  const { request, loading } = useUpdateMeetingParticipantNames();

  React.useEffect(() => {
    if (open) {
      trackEditParticipantNamesModalOpened(meetingId);
    }
  }, [meetingId, open]);

  function checkInputForError(name: string, index: number) {
    if (name.trim() === '') {
      setError(index);
    }
  }

  const handleUpdateParticipantNames = async () => {
    // check each input for empty name
    participantNames.forEach((participant) => {
      checkInputForError(participant.name, participant.index);
    });
    if (error !== null) return;

    const namesToUpdate = participantNames
      .filter((p) => p.name !== initialNames[p.index].name)
      .map((participant) => {
        return {
          originalName: initialNames[participant.index].name,
          updatedName: participant.name,
        };
      });
    if (namesToUpdate.length === 0) {
      onClose();
      return;
    }
    request({
      input: {
        meetingId,
        participantNames: namesToUpdate,
      },
    })
      .then(() => {
        trackMeetingParticipantNameChanged(meetingId);
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'Participant name updated.',
            id: 'rNnqzb',
          }),
          { variant: 'SUCCESS' }
        );
      })
      .catch(() => {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage: 'Something went wrong!',
            id: '/WMCDd',
          }),
          { variant: 'ERROR' }
        );
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={
        <FormattedMessage defaultMessage="Edit participant names" id="pyTyZP" />
      }
      text={
        <FormattedMessage
          defaultMessage="Updating the participants name will update their name through the transcript."
          id="ZtVZmS"
        />
      }
      actions={
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => {
              trackEditParticipantNamesModalClosed(meetingId);
              onClose();
              setParticipantNames(initialNames);
              setError(null);
            }}
            variant="outlined"
          >
            <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
          </Button>
          <Button loading={loading} onClick={handleUpdateParticipantNames}>
            <FormattedMessage defaultMessage="Update" id="BWpuKl" />
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-2 ">
        {participantNames.map((participant) => (
          <div
            className="flex flex-row items-center gap-2"
            key={`${participant.index}`}
          >
            <Avatar
              name={participant.name}
              color={participant.color as SpeakerColourProps}
            />

            <TextInput
              error={error === participant.index}
              placeholder={initialNames[participant.index].name}
              onChange={(event) => {
                checkInputForError(event, participant.index);
                // Reset error that has been set to the input if we have a value
                if (error === participant.index && event.trim() !== '') {
                  setError(null);
                }
                const newParticipantNames = [...participantNames];
                newParticipantNames[participant.index].name = event;
                setParticipantNames(newParticipantNames);
              }}
              value={participant.name}
            />
          </div>
        ))}
      </div>
    </ModalDialog>
  );
};

interface ParticipantStatsProps {
  meetingId: string;
}
/**
 * Participant Stats
 * @param {unknown} param0 params
 * @param {string} param0.meetingId meeting id
 * @returns {React.FC<ParticipantStatsProps>} component
 */
export const ParticipantStats: React.FC<ParticipantStatsProps> = ({
  meetingId,
}) => {
  const [editParticipantsModalOpen, setEditParticipantsModalOpen] =
    React.useState(false);
  const meeting = useMeeting(meetingId);
  const isEnhancedPrivacyEnabled = useSelector(selectIsEnhancedPrivacyEnabled);

  const filteredParticipants = useSelector(
    (state: RootState) => state.global.ui.filteredParticipants[meetingId]
  );

  if (!meeting || isEnhancedPrivacyEnabled) {
    return null;
  }

  return (
    <TranscriptSection
      header={
        <FormattedMessage
          defaultMessage="Speaker Stats"
          id="hC8L6S"
          description="Engagement"
        />
      }
      place="engagement"
      actions={
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="Edit participant names"
              id="pyTyZP"
            />
          }
          arrow
        >
          <Button
            variant="icon"
            onClick={() => setEditParticipantsModalOpen(true)}
          >
            <Edit3 className="size-4" />
          </Button>
        </Tooltip>
      }
    >
      <ParticipantStatsImpl
        participants={uniqBy(meeting.participants, 'name')}
        filteredParticipants={filteredParticipants}
      />
      <EditParticipantNameModal
        open={editParticipantsModalOpen}
        onClose={() => setEditParticipantsModalOpen(false)}
        participants={meeting.participants}
        meetingId={meetingId}
      />
    </TranscriptSection>
  );
};
