import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  MouseEvent as SyntheticMouseEvent,
  TouchEvent as SyntheticTouchEvent,
  PropsWithChildren,
} from 'react';
import { TranscriptBlock } from '../../../../../models/meeting';
import { TranscriptSelection } from '../../../../../models/transcript';
import { Tag } from '../../../../../graphql/operations';

export interface SelectionObserverProps {
  tags: Tag[];
  blocks: TranscriptBlock[];
  onHighlight: (selection: TranscriptSelection, tag?: Tag) => void;
  onRemoveHighlight: (messageIds: string[]) => void;
  onRemoveBlock: (messageIds: string[]) => void;
}

export interface CommentParams {
  groupId: string;
  commentId: string | null;
}

export interface SelectionObserverContextValue {
  selection: TranscriptSelection | null;
  tags: Tag[];
  editingGroupId: string | null;
  commentParams: CommentParams | null;
  onBlockSelection: (groupId: string, messageIds: string[]) => void;
  onBlockSelectionClear: () => void;
  onHighlight: (selection: TranscriptSelection, tag?: Tag) => void;
  onRemoveHighlight: (messageIds: string[]) => void;
  onEditBlock: (groupId: string | null) => void;
  onRemoveBlock: (messageIds: string[]) => void;
  onCommentBlock: (params: CommentParams | null) => void;
}

export const SelectionObserverContext =
  React.createContext<SelectionObserverContextValue>({
    selection: null,
    editingGroupId: null,
    commentParams: null,
    tags: [],
    onBlockSelection: () => undefined,
    onBlockSelectionClear: () => undefined,
    onHighlight: () => undefined,
    onRemoveHighlight: () => undefined,
    onEditBlock: () => undefined,
    onRemoveBlock: () => undefined,
    onCommentBlock: () => undefined,
  });

const getCurrentSelection = (): TranscriptSelection | undefined => {
  const selection = window.getSelection();

  if (!selection) {
    return;
  }

  if (selection.type !== 'Range') {
    return;
  }

  const range = selection.getRangeAt(0);

  let startOffset = range.startOffset;
  let endOffset = range.endOffset;
  const messageIds: string[] = [];
  const elements =
    range.startContainer === range.endContainer
      ? [range.startContainer.parentElement]
      : range.cloneContents().querySelectorAll('[data-tactiq-message-id]') ??
        [];

  for (const element of elements) {
    const messageId = element?.getAttribute('data-tactiq-message-id');

    if (!messageId) {
      return;
    }

    messageIds.push(messageId);
  }

  if (elements[0]?.textContent !== range.startContainer.textContent) {
    startOffset = 0;
  }

  if (
    elements[elements.length - 1]?.textContent !==
    range.endContainer.textContent
  ) {
    endOffset = 0;
  }

  // if selection is empty or too large - just do nothing
  if (messageIds.length === 0 || messageIds.length > 30) {
    return;
  }

  const blockElement =
    selection.anchorNode?.parentElement?.closest('.tactiq-block');

  if (!blockElement) {
    return;
  }

  return {
    groupId: blockElement.getAttribute('data-tactiq-group-id') as string,
    messageIds,
    startOffset,
    endOffset,
    isTextSelection: true,
  };
};

/**
 * Selection Observer
 * @param {unknown} param0 params
 *  @param {Tag[]} param0.tags tags
 * @param {() => void} param0.onHighlight on highlight
 * @param {() => void} param0.onRemoveHighlight on remove highlight
 * @param {() => void} param0.onRemoveBlock on remove block
 * @param {React.ReactNode} param0.children children
 * @returns {React.FC} component
 */
export const SelectionObserver: React.FC<
  PropsWithChildren<SelectionObserverProps>
> = ({ tags, onHighlight, onRemoveHighlight, onRemoveBlock, children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selection, onSelectionChange] = useState<TranscriptSelection | null>(
    null
  );
  const [editingGroupId, setEditingGroupId] = useState<string | null>(null);
  const [commentParams, setCommentParams] = useState<CommentParams | null>(
    null
  );

  const onSelectionChanged = useCallback(
    (event: SyntheticMouseEvent | SyntheticTouchEvent) => {
      const newSelection = getCurrentSelection() ?? null;

      if (newSelection) {
        event.stopPropagation();
        // trackWebEvent('Meeting View - Transcript - Show highlight widget', {
        //   messagesCount: newSelection.messageIds.length,
        // });
      }

      onSelectionChange(newSelection);
    },
    [onSelectionChange]
  );

  const onResetSelection = useCallback(() => {
    onSelectionChange(null);

    const browserSelection = window.getSelection();

    if (browserSelection) {
      if (browserSelection.empty) {
        browserSelection.empty();
      } else if (browserSelection.removeAllRanges) {
        browserSelection.removeAllRanges();
      }
    }
  }, [onSelectionChange]);

  useEffect(() => {
    const selectionListener = (event: MouseEvent | TouchEvent) => {
      if (
        !containerRef.current ||
        containerRef.current.contains(event.target as Node)
      ) {
        return;
      }
      onResetSelection();
    };
    document.addEventListener('mousedown', selectionListener);
    document.addEventListener('touchend', selectionListener, { passive: true });
    return () => {
      document.removeEventListener('mousedown', selectionListener);
      document.removeEventListener('touchend', selectionListener);
    };
  }, [containerRef, onResetSelection]);

  const onBlockSelection = useCallback(
    (groupId: string, messageIds: string[]) => {
      onSelectionChange({
        groupId,
        messageIds,
        startOffset: 0,
        endOffset: 0,
        isTextSelection: false,
      });
    },
    [onSelectionChange]
  );

  const onBlockSelectionClear = useCallback(() => {
    onSelectionChange(null);
  }, [onSelectionChange]);

  const onEditBlock = useCallback(
    (editGroupId: string | null) => {
      setEditingGroupId(editGroupId);
      onResetSelection();
    },
    [onResetSelection]
  );

  const onCommentBlock = useCallback(
    (params: CommentParams | null) => {
      let newCommentParams = params;

      if (newCommentParams === null && selection?.groupId) {
        newCommentParams = { groupId: selection.groupId, commentId: null };
      }

      setCommentParams(newCommentParams);
      onResetSelection();
    },
    [onResetSelection, selection]
  );

  return (
    <div
      className="relative"
      ref={containerRef}
      onMouseUp={onSelectionChanged}
      onTouchEnd={onSelectionChanged}
    >
      <SelectionObserverContext.Provider
        value={{
          editingGroupId,
          commentParams,
          selection,
          tags,
          onBlockSelection,
          onBlockSelectionClear,
          onHighlight,
          onRemoveHighlight,
          onEditBlock,
          onRemoveBlock,
          onCommentBlock,
        }}
      >
        {children}
      </SelectionObserverContext.Provider>
    </div>
  );
};
