import { ChevronDown, ChevronUp, LucideIcon } from 'lucide-react';
import React from 'react';
import { cx } from '../../../helpers/utils';
import { BadgeNew } from '../BadgeNew';

type NavMenuSubItemProps = {
  href: string;
  label: string;
  subItemIsSelected?: boolean;
  isNew?: boolean;
};

export const NavMenuSubItem: React.FC<NavMenuSubItemProps> = ({
  href,
  label,
  subItemIsSelected,
  isNew = false,
}) => {
  return (
    <a
      href={`#${href}`}
      className={cx(
        'group relative flex h-10 items-center rounded-lg px-2 py-2 text-sm hover:bg-slate-50',
        subItemIsSelected ? 'bg-slate-50 text-indigo-600' : 'text-slate-700'
      )}
    >
      <span
        className="relative flex h-4 w-4 flex-shrink-0 items-center justify-center"
        aria-hidden="true"
      >
        <span
          className={cx(
            subItemIsSelected ? 'bg-indigo-500' : 'bg-slate-200',
            'relative z-20 block h-[6px] w-[6px] rounded-full text-indigo-500'
          )}
        />
      </span>
      <div className="flew-row flex items-center gap-x-3">
        <p
          className={cx(
            'ml-4 flex min-w-0 flex-col truncate capitalize',
            subItemIsSelected ? 'text-indigo-600' : 'text-slate-700'
          )}
        >
          {label}
        </p>
        <BadgeNew visible={isNew} />
      </div>
    </a>
  );
};

export const NavItemWithSubItems: React.FC<{
  label: string;
  currentPage: string;
  icon: LucideIcon;
  subItems: NavMenuSubItemProps[];
}> = ({ label, icon: Icon, currentPage, subItems }) => {
  const isSelected =
    label === currentPage || subItems.some((x) => x.label === currentPage);
  const [isOpen, setIsOpen] = React.useState<boolean>(isSelected);
  return (
    <div>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={cx(
          'group relative flex min-h-10 w-full flex-row items-center gap-x-3 rounded-lg p-2 leading-6 hover:bg-slate-50 hover:text-indigo-600 md:bg-none',
          isSelected ? 'bg-white ' : ' '
        )}
      >
        <div
          className={cx(
            'inline-flex ',
            isSelected ? 'text-indigo-600' : 'text-slate-400'
          )}
        >
          <Icon strokeWidth={2} size={20} />
        </div>

        <div className="flex w-full flex-row items-center justify-between gap-x-3 truncate">
          <p
            className={cx(
              'truncate text-sm font-medium capitalize group-hover:text-slate-700',
              isSelected ? 'text-indigo-600' : 'text-slate-700'
            )}
          >
            {label}
          </p>
        </div>

        {isOpen ? (
          <ChevronUp
            strokeWidth={2}
            size={20}
            className="text-slate-400"
            aria-hidden="true"
          />
        ) : (
          <ChevronDown
            strokeWidth={2}
            size={20}
            className="text-slate-400"
            aria-hidden="true"
          />
        )}
      </button>

      {isOpen ? (
        <ul>
          {subItems.map((subItem: NavMenuSubItemProps) => {
            return (
              <li key={subItem.label} className="relative">
                {subItems[subItems.length - 1] !== subItem ? (
                  <div className="absolute left-4 top-4 z-10  mt-[2px] h-full w-px bg-slate-200" />
                ) : null}
                <NavMenuSubItem
                  key={subItem.label}
                  href={subItem.href}
                  label={subItem.label}
                  subItemIsSelected={subItem.label === currentPage}
                  isNew={subItem.isNew}
                />
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};

export const NavMenuItem: React.FC<{
  href: string;
  label: string;
  icon: LucideIcon;
  currentPage: string;
  isNew?: boolean;
  isSubItem?: boolean;
}> = ({
  href,
  label,
  currentPage,
  icon: Icon,
  isNew = false,
  isSubItem = false,
}) => {
  return (
    <a
      className={cx(
        'group relative flex min-h-10 w-full flex-row items-center gap-x-3 rounded-lg p-2 leading-6 hover:bg-slate-50 hover:text-slate-700 md:bg-none',
        label === currentPage ? 'bg-slate-50' : ''
      )}
      href={`#${href}`}
    >
      <div
        className={cx(
          'inline-flex',
          label === currentPage ? 'text-indigo-600' : 'text-slate-400',
          isSubItem ? 'ml-11' : ''
        )}
      >
        <Icon strokeWidth={2} size={20} />
      </div>
      <div className={'flex-1 cursor-pointer overflow-hidden'}>
        <div className="flex flex-row items-center gap-x-3">
          <p
            className={cx(
              'truncate text-left text-sm font-medium capitalize',
              label === currentPage ? 'text-indigo-600' : 'text-slate-700'
            )}
          >
            {label}
          </p>
          <BadgeNew visible={isNew} />
        </div>
      </div>
    </a>
  );
};
