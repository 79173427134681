import { useMutation } from '@apollo/client';
import {
  ExternalLink,
  HelpCircle,
  MessagesSquare,
  Sparkles,
} from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../components/buttons';
import { RequestUpgradeDocument } from '../../graphql/operations';
import {
  KnownEvents,
  TierPricingDialogSource,
  trackWebEvent,
} from '../../helpers/analytics';
import { cx } from '../../helpers/utils';
import {
  selectUid,
  teamBillingPastDue,
  teamBillingRenewalDisabled,
  userBillingPastDue,
  userBillingRenewalDisabled,
} from '../../redux/selectors';
import { RootState } from '../../redux/store';
import { TierPricingDialog } from './TierPricing/TierPricingDialog';
import { Popover } from '../../components/Popover';

const RequestUpgradeButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  return (
    <Button size="small" variant="filled" onClick={onClick}>
      <FormattedMessage defaultMessage="Request upgrade" id="/U9Ppo" />
    </Button>
  );
};

const AboutAICreditInfo: React.FC<{
  isMobileNavOpen?: boolean;
  user?: string;
  proPlan?: boolean;
  team?: string;
  paidTeam?: boolean;
}> = ({ isMobileNavOpen, user, proPlan, team, paidTeam }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const popperId = anchorEl ? 'aiInfoPopper' : undefined;
  return (
    <>
      <Popover
        onOpenChange={(open) => {
          if (!open) {
            trackWebEvent('Closed AI credit info popper');
          }
        }}
        variant="brand"
        placement="right"
        content={
          <div className="border-1 flex max-w-md flex-col gap-2 p-2">
            <div className="flex flex-row justify-between">
              <p className="font-bold">
                <FormattedMessage
                  defaultMessage="What is Tactiq AI Credit?"
                  id="tg/Fq7"
                />
              </p>
            </div>
            <p className="text-xs">
              <FormattedMessage
                defaultMessage="An AI credit is like a token that allows you to use advanced Generative AI features on your meeting transcripts, like AI summary, action items, and more. 1 credit is used per transcript, regardless of the number of AI actions used. Credits refresh monthly and don't roll over."
                id="WrL/zo"
              />
            </p>
            <a
              target="_blank"
              rel="noreferrer"
              className="flex flex-row gap-1 underline"
              href="https://help.tactiq.io/en/articles/8590328-what-is-an-ai-credit"
              onClick={() => {
                trackWebEvent(
                  'Clicked learn more about AI credits in the navigation',
                  {
                    user,
                    proPlan,
                    team,
                    paidTeam,
                  }
                );
              }}
            >
              <p className="text-xs">
                <FormattedMessage
                  defaultMessage="Learn more about AI credits and how it works"
                  id="5qPT7W"
                />
              </p>
              <ExternalLink className="inline-block h-4 w-4" />
            </a>
          </div>
        }
      >
        <button
          aria-describedby={popperId}
          onClick={handleClick}
          type="button"
          className={cx('hidden', isMobileNavOpen ? '' : 'md:inline-block')}
        >
          <HelpCircle className="h-5 w-5 text-slate-400" />
        </button>
      </Popover>
    </>
  );
};

/**
 *
 * @param {unknown} param0 params
 * @param {boolean} param0.isMobileNavOpen is the mobile nav open
 * @param {boolean} param0.mini alternate mini component
 * @returns {React.ReactElement} component
 */
export const MeetingsOrCreditsRemaining: React.FC<{
  isMobileNavOpen?: boolean;
  mini?: boolean;
}> = ({ isMobileNavOpen = false, mini = false }) => {
  const isExtensionUnavailable = !useSelector(
    (state: RootState) => state.global.isExtensionAvailable
  );
  const user = useSelector((state: RootState) => state.user);
  const plan = user.plan;
  const team = user.team;
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const [requestUpgrade] = useMutation(RequestUpgradeDocument);

  const uid = useSelector(selectUid);

  const intl = useIntl();

  const tBillingPastDue = useSelector(teamBillingPastDue);
  const tBillingRenewDisabled = useSelector(teamBillingRenewalDisabled);
  const uBillingPastDue = useSelector(userBillingPastDue);
  const uBillingRenewDisabled = useSelector(userBillingRenewalDisabled);

  const isPaid = plan.paid?.isPaid;
  const isPaidTeam = team?.isPaid;
  const isPaidTeamSeat = user.tier === 'TEAM';
  const isTeamAdmin =
    team?.members.some((m) => m.uid === uid && m.roles.ADMIN) ?? false;

  let type: 'meetings' | 'credits' | undefined;
  let allowed = 0;
  let used = 0;

  if (isPaidTeamSeat) return null;

  let upgradeButton = null;

  if (!team || !team.isPaid) {
    // Free team or no team at all
    upgradeButton = (
      <Button
        size="small"
        variant="filled"
        fullWidth
        onClick={() => {
          trackWebEvent(KnownEvents.PricingUpgradeNavigationClicked);
          setShowTierPricingDialog(true);
        }}
      >
        <FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />
      </Button>
      // <UpgradeToProButton
    );
  } else if (team.isPaid && !user.plan.paid?.isPaid) {
    // Paid team but free user
    upgradeButton = (
      <Button
        size="small"
        variant="outlined"
        fullWidth
        onClick={() => {
          trackWebEvent(KnownEvents.PricingUpgradeNavigationClicked);
          setShowTierPricingDialog(true);
        }}
      >
        <FormattedMessage defaultMessage="Upgrade" id="0h/lPM" />
      </Button>
    );
  } else if (team.isPaid && user.plan.paid?.isPaid && !isTeamAdmin) {
    // Paid team, User had Paid Pro plan and is not admin so cannot self upgrade
    upgradeButton = (
      <RequestUpgradeButton
        onClick={async () => {
          trackWebEvent('Request upgrade to team clicked', {
            location: 'navigation',
            user: uid,
            team: team.id,
          });
          await requestUpgrade().then(() => {
            enqueueSnackbar(
              intl.formatMessage({
                defaultMessage: 'Your request has been sent to the team admin',
                id: 'LsMmVU',
              }),
              {
                variant: 'INFO',
              }
            );
          });
        }}
      />
    );
  }

  if (isTeamAdmin && !isPaid && isPaidTeam) {
    // free admin in a paid team
    type = 'meetings';
    allowed = plan.free.allowance;
    used = plan.free.used;
  } else if (!isPaid && isPaidTeam) {
    // free user in a paid team
    type = 'meetings';
    allowed = plan.free.allowance;
    used = plan.free.used;
  } else if (isPaid && !isPaidTeam) {
    // pro
    type = 'credits';
    allowed = plan.free.aiCredits.allowance;
    used = plan.free.aiCredits.used;
  } else if (isPaid && isPaidTeam && plan.paid?.__typename !== 'TeamPaidPlan') {
    // on a pro seat in a paid team - can consolidate?
    type = 'credits';
    allowed = plan.free.aiCredits.allowance;
    used = plan.free.aiCredits.used;
  } else if (isPaid && isPaidTeam && plan.paid?.__typename === 'TeamPaidPlan') {
    // IMPORTANT: let's not show anything for now, it is confusing the users
    // on a team paid seat
    // type = 'credits';
    // allowed = plan.free.aiCredits.allowance;
    // used = plan.free.aiCredits.used;
    // nowhere else to upgrade
  } else if (tBillingRenewDisabled || uBillingRenewDisabled) {
    // We do not want to show anything if the user is past due
    // User can update payment method and go back to active
    // User is not quite in the !isPaid state
  } else if (tBillingPastDue || uBillingPastDue) {
    // Same as above
  } else if (!plan.paid?.isPaid) {
    // Free user
    type = 'meetings';
    allowed = plan.free.allowance;
    used = plan.free.used;
  }

  if (isExtensionUnavailable && mini) {
    return (
      <div className="border-t border-slate-200">
        <div className="flex flex-col gap-y-2 px-3 py-2">
          <div className="flex flex-row justify-between"></div>
          <p className="text-xs">
            {type === 'meetings' ? (
              <FormattedMessage
                defaultMessage="You've used {used} of your {total} meetings this month."
                id="Skk45D"
                values={{
                  used,
                  total: allowed,
                }}
              />
            ) : (
              <div className="flex flex-row justify-between gap-2">
                <FormattedMessage
                  defaultMessage="You've used {used} of your {total} available AI credits this month."
                  id="ViSkga"
                  values={{
                    used,
                    total: allowed,
                  }}
                />
                <AboutAICreditInfo
                  isMobileNavOpen={isMobileNavOpen}
                  user={uid}
                  proPlan={isPaid}
                  team={team?.id}
                  paidTeam={isPaidTeam}
                />
              </div>
            )}
          </p>
          <div className=" w-full overflow-hidden rounded-full bg-gray-200">
            {/* Percentage bar */}
            <div
              className="h-1 rounded bg-indigo-600"
              style={{
                width: `${100 - (used / allowed) * 100}%`,
              }}
            />
            {/* Percentage bar */}
          </div>
          {upgradeButton && (
            <div className="mt-2 w-fit justify-start">{upgradeButton}</div>
          )}
          {showTierPricingDialog && (
            <TierPricingDialog
              source={TierPricingDialogSource.NAVIGATION}
              onClose={() => setShowTierPricingDialog(false)}
              userTier={user.tier}
              teamTier={team?.tier}
            />
          )}
        </div>
      </div>
    );
  }

  if (isExtensionUnavailable) return null;
  if (!type) return null;

  return (
    <div className="border-t border-slate-200">
      <div className="flex flex-col gap-y-2 p-4">
        <div className="flex flex-row justify-between">
          {type === 'meetings' ? (
            <div className="flex flex-row gap-2">
              <MessagesSquare className="h-5 w-5 text-indigo-600" />
              <p className="text-sm font-semibold">
                <FormattedMessage
                  defaultMessage="Tactiq Meetings"
                  id="xWOw1c"
                />
              </p>
            </div>
          ) : (
            <div className="flex flex-row gap-2">
              <Sparkles className="h-5 w-5 text-indigo-600" />
              <p className="text-sm font-semibold">
                <FormattedMessage defaultMessage="AI Credits" id="xIDpk3" />
              </p>
            </div>
          )}
          {type === 'credits' && (
            <AboutAICreditInfo
              isMobileNavOpen={isMobileNavOpen}
              user={uid}
              proPlan={isPaid}
              team={team?.id}
              paidTeam={isPaidTeam}
            />
          )}
        </div>
        <p className="text-xs">
          {type === 'meetings' ? (
            <FormattedMessage
              defaultMessage="You've used {used} of your {total} meetings this month."
              id="Skk45D"
              values={{
                used,
                total: allowed,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="You've used {used} of your {total} available AI credits this month."
              id="ViSkga"
              values={{
                used,
                total: allowed,
              }}
            />
          )}
        </p>
        <div className=" w-full overflow-hidden rounded-full bg-gray-200">
          {/* Percentage bar */}
          <div
            className="h-1 rounded bg-indigo-600"
            style={{
              width: `${100 - (used / allowed) * 100}%`,
            }}
          />
          {/* Percentage bar */}
        </div>
        {upgradeButton && (
          <div className="mt-2 w-fit justify-start">{upgradeButton}</div>
        )}
      </div>
      {showTierPricingDialog && (
        <TierPricingDialog
          source={TierPricingDialogSource.NAVIGATION}
          onClose={() => setShowTierPricingDialog(false)}
          userTier={user.tier}
          teamTier={team?.tier}
        />
      )}
    </div>
  );
};
