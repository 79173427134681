import React, { ReactElement, useEffect, useState } from 'react';
import { Page } from '../Common/Navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/buttons';
import {
  Compass,
  Copy,
  Ellipsis,
  History,
  Pencil,
  Plus,
  X,
} from 'lucide-react';
import { Table } from '../../components/Table';
import { DeadEnd } from '../../components/DeadEnd';
import { Menu } from '../../components/Menu';
import {
  useWorkflowArchive,
  useWorkflowCreate,
  useWorkflowDuplicate,
  useWorkflowList,
} from '../../services/Workflow';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router';
import { ConfirmActionDialog } from '../../components/modals';
import { trackWebEvent, viewWorkflowListPage } from '../../helpers/analytics';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../components/ToggleButtonGroup';
import { WorkflowsType } from '../../graphql/operations';
import { Avatar } from '../../components/Avatar';
import { Tooltip } from '../../components/Tooltip';
import { workflowDiscovery } from '../../helpers/routes';

export default function WorkflowList(): ReactElement {
  const intl = useIntl();
  const navigate = useNavigate();
  const title = intl.formatMessage({
    defaultMessage: 'My workflows',
    id: 'KjL2+I',
  });
  const [type, setType] = useState(WorkflowsType.ALL);
  const types = [
    {
      id: WorkflowsType.ALL,
      name: intl.formatMessage({ defaultMessage: 'All', id: 'zQvVDJ' }),
    },
    {
      id: WorkflowsType.TEAM,
      name: intl.formatMessage({ defaultMessage: 'Team', id: 'wsUmh9' }),
    },
    {
      id: WorkflowsType.MINE,
      name: intl.formatMessage({ defaultMessage: 'Mine', id: 'snC7V6' }),
    },
    {
      id: WorkflowsType.USED,
      name: intl.formatMessage({ defaultMessage: 'Used', id: 'XYkq4n' }),
    },
  ];

  useEffect(() => {
    viewWorkflowListPage();
  }, []);

  return (
    <Page title={title}>
      <div className="flex flex-wrap items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold">{title}</h1>
          <p className="mt-3 text-gray-500">
            <FormattedMessage
              defaultMessage="Create and manage workflow automations here"
              id="mkBOdN"
            />
          </p>
        </div>
        <div className="flex items-center gap-x-4">
          <ProvideFeedbackButton />
          <CreateWorkflowButton />
        </div>
      </div>
      <div className="my-6 flex items-center gap-3">
        <ToggleButtonGroup value={type} onChange={setType} style="disconnected">
          {types.map((type) => (
            <ToggleButton key={type.id} value={type.id}>
              {type.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Button
          variant="naked"
          size="small"
          startIcon={<Compass size="1rem" />}
          onClick={() => navigate(workflowDiscovery)}
        >
          <FormattedMessage defaultMessage="Workflow Library" id="mDVer5" />
        </Button>
      </div>

      <WorkflowTable type={type} />
    </Page>
  );
}

function WorkflowTable({ type }: { type: WorkflowsType }): ReactElement {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useWorkflowList({
    input: { type },
  });
  const archive = useWorkflowArchive();
  const [archiveId, setArchiveId] = useState<null | string>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading) {
      trackWebEvent('Workflow list Viewed', {
        type,
        workflowsCount: data?.workflows?.length,
      });
    }
  }, [data?.workflows?.length, loading, type]);

  if (error) throw error;
  if (!data?.workflows && loading)
    return <Spinner className="m-auto my-16" size="1.5rem" />;

  const workflows = (data?.workflows ?? []).filter((ii) => !ii.isArchived);

  if (workflows.length === 0)
    return (
      <div className="my-16">
        <DeadEnd
          title={formatMessage({
            defaultMessage: 'Create your first workflow',
            id: 'LhWBrL',
          })}
          description={formatMessage({
            defaultMessage:
              'Streamline your after meeting workflow by automating tasks. Use Tactiq Al prompts and connect with third-party apps to setup triggers and actions',
            id: 'uW0puE',
          })}
          action={<CreateWorkflowButton />}
        />
      </div>
    );
  return (
    <>
      <Table
        initialPageSize={100}
        onRowClick={(row) => navigate(`/workflows/${row.id}`)}
        columns={[
          {
            accessorKey: 'name',
            header: formatMessage({ defaultMessage: 'Name', id: 'HAlOn1' }),
            enableSorting: true,
            cell: ({ row }) => row.original.name,
          },
          {
            accessorKey: 'owner',
            header: formatMessage({ defaultMessage: 'Owner', id: 'zINlao' }),
            enableSorting: true,
            cell: ({ row }) => (
              <span className="flex items-center gap-2">
                <Avatar
                  name={row.original.createdBy.displayName}
                  src={row.original.createdBy.avatarUrl}
                  size="sm"
                />
                {row.original.createdBy.displayName}
              </span>
            ),
          },
          {
            accessorKey: 'triggerType',
            header: formatMessage({
              defaultMessage: 'Trigger type',
              id: '6Vngei',
            }),
            enableSorting: true,
            cell: () => (
              <span className="rounded-lg border border-gray-400 px-3  text-xs">
                Manual
              </span>
            ),
          },
          {
            id: 'edit',
            meta: { align: 'right', stopEventPropagation: true },
            size: 60,
            cell: ({ row }) => (
              <Tooltip
                arrow
                placement="top"
                title={
                  <FormattedMessage
                    defaultMessage="Edit workflow"
                    id="1Ufa47"
                  />
                }
              >
                <Button
                  variant="icon"
                  size="small"
                  onClick={() => {
                    trackWebEvent('Workflow Editor Opened');
                    navigate(`/workflows/${row.original.id}`);
                    // event.stopPropagation();
                  }}
                  className="hidden group-hover/row:inline-block"
                >
                  <Pencil size="1rem" />
                </Button>
              </Tooltip>
            ),
          },
          {
            id: 'actions',
            meta: { stopEventPropagation: true },
            cell: ({ row }) => (
              <div className="flex gap-x-3">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <FormattedMessage defaultMessage="History" id="djJp6c" />
                  }
                >
                  <Button
                    variant="icon"
                    size="small"
                    onClick={() => {
                      trackWebEvent('Workflow History Opened');
                      navigate(`/workflows/${row.original.id}/activity`);
                    }}
                  >
                    <History size="1rem" />
                  </Button>
                </Tooltip>
                <DuplicateWorkflowButton id={row.original.id} />

                <Menu>
                  <Menu.Trigger>
                    <Button variant="naked">
                      <Ellipsis size="1rem" />
                    </Button>
                  </Menu.Trigger>
                  <Menu.Item
                    icon={<X size="1rem" />}
                    className="text-red-500"
                    onClick={() => {
                      setArchiveId(row.original.id);
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Delete Workflow"
                      id="LzXP/m"
                    />
                  </Menu.Item>
                </Menu>
              </div>
            ),
          },
        ]}
        data={workflows}
      />

      {archiveId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage defaultMessage="Delete workflow" id="RPw47l" />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to delete this workflow?"
              id="dqGjs3"
            />
          }
          yes={<FormattedMessage defaultMessage="Delete" id="K3r6DQ" />}
          yesProps={{ loading: archive.loading, color: 'error' }}
          onYes={async () => {
            await archive.request({
              input: { id: archiveId, isArchived: true },
            });
            trackWebEvent('Workflow Deleted');
            setArchiveId(null);
          }}
          onNo={() => setArchiveId(null)}
        />
      )}
    </>
  );
}

function CreateWorkflowButton() {
  const { data, request, loading } = useWorkflowCreate();
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const id = data?.createWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${id}`);
    }
  }, [id, isSuccess, navigate]);

  return (
    <Button
      loading={loading}
      startIcon={<Plus size="1rem" />}
      onClick={() => request()}
      size="small"
    >
      <FormattedMessage defaultMessage="New workflow" id="GIHRKG" />
    </Button>
  );
}

function ProvideFeedbackButton() {
  return (
    <Button
      href="https://tactiq.typeform.com/ai-workflow"
      target="_blank"
      variant="secondaryOutline"
      size="small"
      onClick={() => {
        trackWebEvent('Provide Feedback Button Clicked');
      }}
    >
      <FormattedMessage defaultMessage="Provide feedback" id="GdjHV6" />
    </Button>
  );
}

function DuplicateWorkflowButton({ id }: { id: string }) {
  const { data, request, loading } = useWorkflowDuplicate(id);
  const isSuccess = Boolean(data && !loading);
  const navigate = useNavigate();
  const newId = data?.duplicateWorkflow.id;

  useEffect(() => {
    if (isSuccess) {
      navigate(`/workflows/${newId}`);
    }
  }, [newId, isSuccess, navigate]);

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <FormattedMessage defaultMessage="Duplicate workflow" id="IynmOz" />
      }
    >
      <Button
        variant="icon"
        size="small"
        onClick={() => request()}
        loading={loading}
      >
        {!loading && <Copy size="1rem" />}
      </Button>
    </Tooltip>
  );
}
